@import "./theme.css";

@font-face {
  font-family: "Poppins-Light";
  src: url("../fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins-Bold.ttf");
}

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

/* body{
    overflow: hidden;
} */

.cus-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cus-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pt-0 {
  padding-top: 0px;
}

.pt-100 {
  padding-top: 100px;
}

.mt-10 {
  margin-top: 10px;
}

a {
  text-decoration: none;
}

.login-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  opacity: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.login-inner-main {
  padding: 5px;
  min-width: 360px;
  max-width: 360px;
  text-align: center;
}

.auth-main {
  height: 80vh;
  overflow: auto;
}

.auth-main::-webkit-scrollbar {
  display: none;
}

.register-inner-main {
  min-width: 600px;
  max-width: 600px;
  text-align: center;
}

.main-logo {
  width: 206px;
  margin: 0 0 40px 0;
  object-fit: contain;
}

.main-logo-register {
  margin: 0 0 19px 0;
}

.login-inner-main h1 {
  font-size: 30px;
  line-height: 43px;
  font-family: "Poppins-Bold";
  /* color: #ffffff; */
  color: var(--dark-text);
  margin-bottom: 10px;
}

.register-inner-main-cus h1 {
  line-height: 22px;
}

.login-inner-main .blue-text {
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Light";
  /* color: #7b91c2; */
  /* color: var(--login-page-text); */
  margin: 0 0 40px 0;
}

.select-medium-theme .login-inner-main .blue-text,
.select-medium-theme .login-inner-main .blue-text {
  color: rgb(150, 153, 152);
}

.select-light-theme .login-inner-main .blue-text,
.select-light-theme .login-inner-main .blue-text {
  color: rgb(68, 68, 68);
}

.select-light-theme .login-inner-main .cus-forgot-sub-heading,
.select-light-theme .login-inner-main .cus-forgot-sub-heading {
  color: rgb(2 2 2 / 90%);
}

.select-dark-theme .login-inner-main .blue-text,
.select-dark-theme .login-inner-main .blue-text {
  color: rgb(150, 153, 152);
}

.select-medium-theme .MuiInputAdornment-root .MuiIconButton-root svg,
.select-dark-theme .MuiInputAdornment-root .MuiIconButton-root svg {
  fill: #fff;
}

.select-dark-theme .custom-login-inner .form-group,
.select-light-theme .custom-login-inner .form-group,
.select-medium-theme .custom-login-inner .form-group {
  border: 1px solid var(--text-field-border);
  border-radius: 6px;
}

.select-dark-theme .register-password .form-group,
.select-light-theme .register-password .form-group,
.select-medium-theme .register-password .form-group {
  border: 1px solid var(--text-field-border);
  border-radius: 6px;
}

.custom-login-inner .form-group .form-control input {
  border: none;
}

.form-group .form-control input {
  border: 1px solid var(--text-field-border);
  border-radius: 6px;
  width: 100%;
  padding: 12.5px 20px;
  /* background: #1d2330; */
  background: var(--textfield-background);
  /* color: #ffffff; */
  color: var(--dark-text);
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Light";
  /* height: 50px; */
}

.resend-container {
  display: flex;
  justify-content: end;
}

.resend-container button {
  text-transform: none;
}

.form-group .form-control input::placeholder {
  color: #ffffff;
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Light";
  opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--modal-button-border) !important;
  border-width: 0px;
}

fieldset {
  border: 0px;
}

.MuiInputBase-formControl:hover,
.MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.form-control-textarea:focus-visible {
  outline: none !important;
}

.header-right .form-group .form-control fieldset {
  border: 0px;
  border-width: 0px;
  border-color: transparent;
}

.cus-search-input
  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: transparent !important;
}

.cus-input-grp .cus-lable {
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Light";
  color: var(--dark-text);
  text-align: left;
  margin: 0 0 15px 0;
}

.cus-input-grp {
  text-align: left;
  margin-top: 30px;
}

.register-inner-main .cus-input-grp {
  text-align: left;
  margin-top: 10px;
}

.register-inner-main .register-input-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
}

.register-inner-main .password-input-grp {
  width: 295px;
}

.primary-btn-main .primary-btn {
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Regular";
  /* background: #5863e5; */
  width: 100%;
  border-radius: 6px;
  border: none;
  /* background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0%
    0%; */
  background: var(--gradient-button);
  /* color: #ffffff; */
  color: var(--light-text);
  text-transform: capitalize;
}

.primary-btn-main .login-btn {
  height: 50px;
  max-width: 360px;
  margin: 25px 0;
  padding-right: 0.5px;
}

.select-light-theme .primary-btn-main .custom-btn:hover {
  background-image: linear-gradient(rgb(85, 164, 88), #75a691);
}

.select-dark-theme .primary-btn-main .custom-btn:hover {
  background-image: linear-gradient(rgb(85, 164, 88), #75a691);
}

.select-medium-theme .primary-btn-main .custom-btn:hover {
  background: linear-gradient(#323ec0, #797dbe);
}

.select-medium-theme .custom-forgot-box a span:hover,
.select-medium-theme .custom-login-inner .cus-link span:hover,
.select-medium-theme .resend-container .forgot-link:hover,
.select-medium-theme .login-inner-main .cus-link span:hover {
  color: #3960b5;
}

.select-dark-theme .custom-forgot-box a span:hover,
.select-dark-theme .custom-login-inner .cus-link span:hover,
.select-dark-theme .resend-container .forgot-link:hover,
.select-dark-theme .login-inner-main .cus-link span:hover {
  color: rgb(133, 219, 169);
}

.select-light-theme .custom-forgot-box a span:hover,
.select-light-theme .custom-login-inner .cus-link span:hover,
.select-light-theme .resend-container .forgot-link:hover,
.select-light-theme .login-inner-main .cus-link span:hover {
  color: rgb(2, 94, 41);
}

.forgot-link-main {
  width: 100%;
  display: flex;
  justify-content: end;
}

.move-login {
  padding-left: 230px;
}

a.forgot-link {
  /* color: #ffffff; */
  color: var(--dark-text);
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Light";
  cursor: pointer;
  /* margin-top: 5px; */
  margin: 5px 0px 5px 0px;
  /* padding-left: 170px; */
}

/* a.forgot-link:hover {
  color: transparent;
  background: linear-gradient(rgb(18, 102, 21), #025a34);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

a.resend-timer {
  /* color: #ffffff; */
  color: var(--dark-text);
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Light";
  cursor: pointer;
  margin-top: 5px;
  padding-left: 60px;
}

a.forgot-link .forgot-text {
  /* color: #ffffff; */
  color: var(--dark-text);
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Light";
  cursor: pointer;
  margin-top: 5px;
}

.select-dark-theme a.cus-link span {
  color: rgb(105, 179, 135);
}

.select-light-theme a.cus-link span {
  color: #08844e;
}

.select-medium-theme a.cus-link span {
  color: #5270b2;
}

a:hover {
  text-decoration: none;
}

.otp-sec .form-group {
  min-width: 50px;
  /* margin-right: 28px; */
}

.otp-sec .form-group .form-control input {
  padding: 12.5px 19px;
  /* font-size: 20px;
    line-height: 15px;
    font-family: "Poppins-Regular"; */
}

.otp-sec .form-group .form-control-textarea:focus-visible {
  outline: none !important;
}

.otp-inpt-grp {
  display: flex;
}

.me-0 {
  margin-right: 0;
}

.header-wrapper {
  height: 45px;
  /* background: #141922; */
  background: var(--header-background);
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  border: 2px solid var(--header-border);
}

.header-left {
  display: flex;
  align-items: center;
}

.grid-list-btn-sec {
  min-width: 60px;
  max-width: 400px;
  height: 35px;
  /* background: #1d2330; */
  background: var(--header-svg-background);
  border-radius: 8px;
  margin: 0 5px 0 0;
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left .main-logo {
  margin-bottom: 0;
}

.list-btn {
  margin-right: 15px;
}

.grid-list-btn {
  cursor: pointer;
}

.gray-bg-btn-main .gray-bg-btn {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
  background: #42506f;
  width: 100%;
  border-radius: 4px;
  border: none;
  padding: 0;
}

.script-btn .gray-bg-btn-main .gray-bg-btn {
  /* background: #1d2330; */
  background: var(--header-svg-background);
  border-radius: 4px;
  padding: 5px 5px 5px 5px;
  color: white;
  text-transform: capitalize;
}

.script-btn .gray-bg-btn-main .gray-bg-btn img {
  margin: 0 0 0 10px;
}

.market-main-box {
  /* height: 52px; */
  /* padding: 6.5px 10px; */
  margin-right: 5px;
}

.gray-bg-btn-main .more-index-btn {
  width: 90px;
  height: 30px;
}

.main-ul {
  display: flex;
}

.main-ul .main-li {
  margin-right: 3px;
}

.main-ul .main-li:last-child {
  margin-right: 0;
}

.main-ul .main-li .btn-bg {
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #1d2330; */
  background: var(--header-svg-background);
  border-radius: 8px;
  cursor: pointer;
}

.main-ul .main-li .time-bg {
  cursor: default;
}

.main-ul .main-li .btn-bg .header-menu-icon {
  fill: #ffffff;
}

.main-ul .main-li .btn-bg-active {
  min-width: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #1d2330; */
  background: var(--header-svg-background);
  border-radius: 8px;
  cursor: pointer;
}

.hover-dropdown:hover {
  background: var(--sidebar-svg-background-hover) !important;
}

/* .btn-bg {
  position: relative;
} */

.hover-dropdown:hover .head-btn-img,
.active-sidebar-btn .head-btn-img {
  filter: invert(100%);
}
.active-sidebar-btn {
  background: var(--sidebar-svg-background-hover) !important;
}
.hover-dropdown:hover .sidebar-pi-btn {
  filter: invert(100%);
}

.head-btn-img {
  width: 17px;
  height: 17px;
  object-fit: cover;
}

.select-light-theme .head-btn-img.zoom-img {
  filter: invert(100%);
}

.head-btn-img:hover {
  width: 20px;
  height: 20px;
  object-fit: cover;
}
.header-search {
  margin-right: 14px !important;
}

.main-li .cus-search-input .MuiOutlinedInput-input {
  /* background: #1d2330; */
  background: var(--textfield-background);
  border: 1px solid var(--text-field-border) !important;
  border-radius: 8px;
  /* color: #7b91c2; */
  color: var(--dark-text);
  padding: 13.9px 24px 13.1px 24px;
  min-width: calc(254px - 48px);
  margin: 0 0 0 0;
  /* height: 50px; */
}

.header-right .main-ul,
.header-right .main-li {
  padding: 0;
}

.header-right .main-ul .main-li .notification-badge .MuiBadge-badge {
  font-size: 11px;
  min-width: 17px;
  height: 17px;
  padding: 0px;
}

.sidebar-main {
  /* width: 80px; */
  /* border-top: 2px solid #202748; */
  /* border-left: 2px solid #202748; */
  /* background: #141922; */
  /* background: var(--sidebar-background); */
  position: fixed;
  top: 46px;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: end;
  justify-content: end;
  overflow-y: auto;
  overflow-x: hidden;
}

/* width  */
.sidebar-main.sidebar-left::-webkit-scrollbar {
  width: 5px;
  display: none;
}

/* Firefox */
.sidebar-main.sidebar-left {
  overflow-y: scroll;
  scrollbar-width: none;
}

/* Microsoft Edge and Internet Explorer */
.sidebar-main.sidebar-left {
  -ms-overflow-style: none;
}

.sidebar-left {
  right: auto;
  direction: rtl;
}

.sidebar-left .sidebar-wraqpper {
  direction: ltr;
}

.dashboard-wrapper {
  padding-top: 45px;
  /* background: #12161f; */
  background: var(--body-background);
  /* height: 100vh; */
}

.main-content-sec {
  margin-right: 45px;
  padding: 5px;
  min-height: calc(100vh - 45px);
  position: relative;
}

.hide-main-content-margin {
  margin: 0;
}

.main-content-sec-right {
  margin-right: 0px;
  margin-left: 45px;
}


.sidebar-wraqpper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 45px;
  /* margin-right: 83px; */
  /* background: #141922; */
  /* background: var(--sidebar-background); */
  border-top: 2px solid var(--sidebar-border);
  /* border-left: 2px solid #202748; */
  /* overflow-y: auto; */
}

.siebar-inner-wrapper {
  background: var(--sidebar-background);
}

.sidebar-top,
.sidebar-bottom {
  position: relative;
}

.sidebar-top::before,
.sidebar-bottom::before {
  content: " ";
  width: 2px;
  height: 100%;
  /* background: #202748; */
  background: var(--sidebar-border);
  position: absolute;
  left: 0;
}

.sidebar-top-left::after,
.sidebar-bottom-left::after {
  content: " ";
  width: 2px;
  height: 100%;
  background: var(--sidebar-border);
  position: absolute;
  right: 0;
  top: 0px;
}

.sidebar-top .main-ul {
  display: block;
  /* padding: 30.5px 14.31px 30.46px 15.5px; */
  /* padding: 10px 14.31px 10px 15.5px; */
  padding: 5px;
  border-bottom: 2px solid var(--sidebar-border);
}

.sidebar-top .main-ul .main-li,
.sidebar-bottom .main-ul .main-li {
  padding: 0;
  margin-bottom: 5px;
  margin-right: 0;
}

.sidebar-bottom .main-ul {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  /* padding: 30.5px 14.31px 15.8px 15.5px; */
  /* padding: 10px 14.31px 10px 15.5px; */
  padding: 5px;
  border-bottom: 2px solid var(--sidebar-border);
}

.sidebar-top .main-ul .main-li:last-child,
.sidebar-bottom .main-ul .main-li:last-child {
  margin-bottom: 0;
}

.btn-bg .i-img {
  width: 8px;
  height: 25px;
  object-fit: cover;
}

.btn-bg .i-img:hover {
  width: 8px;
  height: 20px;
}

.sidebar-second-sec,
.sidebar-bottom {
  /* height: 100%; */
}

.sidebar-time-sec {
  /* padding: 0 14.5px 15.8px 15px;
    position: absolute;
    bottom: 0; */
  margin-top: auto;
}

.main-li .side-time {
  color: #ffffff;
  font-size: 8px;
  line-height: 11px;
  font-family: "Poppins-Regular";
  text-align: center;
}

.li-hover {
  min-width: 150px;
  height: 238px;
  /* background: #1d2330; */
  background: var(--sidebar-menu-box-background);
  border-radius: 8px;
  position: absolute;
  right: 44px;
  top: 0px;
  display: none;
}

.li-hover-positional {
  height: auto;
}

.li-hover .time-ul {
  padding: 25px 0;
}

.li-hover .time-li {
  color: #ffffff;
  text-align: center;
  padding: 8px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.li-hover .time-li {
  color: #fff;
  text-align: center;
  padding: 8px 30px;
  display: flex;
  justify-content: center;
}

.li-hover .time-li .mint-text {
  /* color: #8A8A8A; */
  color: var(--text-color);
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Regular";
  cursor: pointer;
}

.li-hover .time-li .hover-state {
  display: none;
}

.li-hover .time-li.active,
.head-menu ul li.active,
.head-menu ul li:hover {
  /* background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0%
    0%; */
  background: var(--sidebar-menu-box-button) !important;
  color: var(--light-text) !important;
}

.li-hover .time-li.active p {
  /* color: #ffffff; */
  color: var(--light-text) !important;
}

.sidebar-top .main-li:hover .li-hover,
.sidebar-top .main-li:hover .menu-name,
.sidebar-bottom .main-li:hover .menu-name {
  display: block;
}

.sidebar-top .main-li:hover .li-hover-none {
  display: none;
}

.chart-list {
  width: 250px;
  height: 201px;
}

.chart-list .time-li {
  justify-content: flex-start;
}

.time-li .chart-ic {
  width: 23px;
  height: 23px;
  object-fit: cover;
  margin-right: 9px;
}

.menu-name {
  width: max-content;
  /* background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0% 0%; */
  background: var(--sidebar-menu-button);
  padding: 8.5px 20px;
  border-radius: 6px 0 0 6px;
  right: 44px;
  position: absolute;
  color: #ffffff;
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Regular";
  display: none;
}

.analysis-list-icon-main,
.dashboard-icon-main {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 6px;
}

.dashboard-icon-main {
  padding-left: 0;
}

.analysis-list-icon-main .analysis-window-btn,
.dashboard-icon-main .grid-img-sec {
  min-width: 20px;
  padding: 0;
}

.analysis-list-icon-main:hover .analysis-window-btn svg path,
.dashboard-icon-main:hover .grid-img-sec svg path {
  fill: #fff;
}

.btn-bg .reset-ic {
  width: 21px;
  height: 17px;
}

.chart-header-main {
  /* background: #1e2431; */
  background: var(--chart-header-background) !important;

  border-radius: 6px 6px 0 0;
  padding: 13px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expiry-chart-header {
  position: relative;
}

.expiry-chart-header .expiry-box {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 6px 0 0 0;
  background-color: red;
  padding: 0 4px;
}

.expiry-chart-header .expiry-box .expiry-text {
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Medium";
  color: #ffffff;
}

.red-color {
  color: #f92727 !important;
}

.green-color {
  color: #2ebe54;
}

.chart-header-left {
  display: flex;
  /* align-items: center; */
  /* flex-direction: column; */
  flex-wrap: wrap;
}

.chart-header-left .chart-text {
  font-size: 18px;
  line-height: 33px;
  font-family: "Poppins-Medium";
  margin: 0 20.6px 0 0;
  display: flex;
  align-items: center;
}

.main-chart-box .chart-header-time,
.small-chart-box .chart-header-time,
.modal-header-sec .chart-header-time {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.chart-header-time .chart-updated-time-text {
  font-size: 10px;
  line-height: 10px;
  font-family: "Poppins-Regular";
  color: var(--dark-text);
  margin-top: 12px;
}

.chart-hover-sec .chart-header-time .chart-updated-time-text {
  color: #ffffff;
}

.chart-hover-sec .chart-header-left .chart-text {
  display: flex;
  align-items: center;
}

.chart-header-left .chart-title {
  /* color: #ffffff; */
  color: var(--dark-text);
  text-transform: uppercase;
}
.chart-hover-sec .chart-header-left .chart-title {
  color: #ffffff;
}

.chart-header-left .value-text {
  /* color: #ffffff; */
  color: var(--dark-text) !important;
}

.chart-header-left .value-text .green-color {
  margin-left: 10px;
}

.red-down-aroow {
  width: 15.33px;
  height: 13px;
  margin-left: 10.5px;
  font-family: "Poppins-Regular";
}

.chat-header-right .chart-time-sec {
  font-size: 20px;
  line-height: 28px;
  font-family: "Poppins-Regular";
  /* color: #5a76b4; */
  color: var(--dark-text);
  margin-right: 10px;
}

.red-btn-main .red-btn {
  background: #ff0000;
  border-radius: 40px;
  font-size: 20px;
  line-height: 28px;
  font-family: "Poppins-Regular";
  color: #ffffff;
  text-transform: capitalize;
}

.red-btn-main .green-btn {
  background: #008000;
  border-radius: 40px;
  font-size: 20px;
  line-height: 28px;
  font-family: "Poppins-Regular";
  color: #ffffff;
  text-transform: capitalize;
}

.red-btn-main .blue-btn {
  background: #3a6dd6 !important;
  border-radius: 40px;
  font-size: 20px;
  line-height: 28px;
  font-family: "Poppins-Regular";
  color: #ffffff;
  text-transform: capitalize;
}

.red-btn-main .buy-btn {
  height: 30px;
  min-width: 96px;
  /* margin: 0px 20px 0px 20px; */
}

.chat-header-right {
  display: flex;
  align-items: center;
  justify-content: end;
}

.chat-header-right .tool-icon-btn {
  border-radius: 5px;
  padding: 3px;
  display: none;
}

.mini-chart-modal-box .chat-header-right .tool-icon-btn {
  border-radius: 5px;
  padding: 3px;
  margin-right: 10px;
  display: flex;
}

.chat-header-right-wrapper {
}

.red-btn-main .buy-btn:hover {
  /* background: #ff0000; */
}

.round-btn-main .round-btn {
  min-width: 30px;
  height: 30px;
  /* background: #42506f; */
  background: var(--chart-header-dot-svgbackground) !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.round-btn-main .download-btn {
  margin-right: 20px;
}

.round-btn-main .chart-download-btn {
  margin-right: 5px;
}

.three-dot {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* .dashboard-three-dot {
  margin-left: 10px;
} */

.dots-img {
  width: 10px;
  height: 20px;
}

.handle {
  position: fixed;
  /* min-width: 500px !important; */
  background-color: #1d2330;
  border-radius: 8px;
  z-index: 999;
}

.hide-modal {
  display: none;
}

.modal-wrapper {
  width: 600px;
  color: var(--dark-text);
  background: var(--modal-body-background);
  /* border-radius: 8px; */
}

.select-light-theme .modal-wrapper {
  background: #cce6cc;
}

.select-medium-theme .modal-wrapper {
  background: #1d2330;
}

.select-dark-theme .modal-wrapper {
  background: #232323;
}

.modal-wrapper .modal-header {
  min-height: 50px;
  padding: 8.5px 8px 8.5px 16.04px;
  background: var(--modal-header-background);
}

.select-light-theme .modal-wrapper .modal-header {
  background: #b6e5b8;
}

.select-medium-theme .modal-wrapper .modal-header {
  background: #1d2330;
}

.select-dark-theme .modal-wrapper .modal-header {
  background: #222222;
}

.cus-vertical-center {
  display: flex;
  align-items: center;
}

.modal-close-btn {
  width: 33px;
  height: 33px;
  /* background: #283142; */
  background: var(--modal-header-svg-background) !important;
  border-radius: 4px;
  margin: 0 0 0 8px;
}

.modal-wrapper .model-header-left h6 {
  font-size: 18px;
  line-height: 27px;
  font-family: "Poppins-Medium";
  color: var(--dark-text);
  margin: 0 0 0 8px;
}

.opacity-progressbar-sec {
  width: 73px;
  display: flex;
  flex-direction: column;
  margin: 0 15px 0 8px;
}

.progressbar-main {
  position: relative;
  margin: 0 4px 0 auto;
}

.progressbar-main .prog-line {
  width: 59px;
  height: 2px;
  background: #ffffff;
  border-radius: 2px;
}

.progressbar-main .prog-round {
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 6px;
  position: absolute;
  right: -4px;
  top: 50%;
  transform: translateY(-50%);
}

.btn-bg {
  background: var(--header-svg-background);
  width: 33px;
  height: 33px;
  border-radius: 4px;
  cursor: pointer;
}

.header-search-svg {
  fill: var(--header-search-icon-fill);
}

.header-chart-svg {
  fill: var(--header-chart-icon-fill);
}

.header-setting-svg {
  fill: var(--header-setting-icon-fill);
}

.header-setting-svg {
  fill: var(--header-setting-icon-fill);
}

.header-zoom-svg {
  fill: var(--header-zoom-icon-fill);
}

.sidebar-i-svg {
  fill: var(--sidebar-i-svg);
}

.sidebar-bxlinechart-svg {
  fill: var(--sidebar-bxlinechart-svg);
}

.sidebar-linechart-svg {
  fill: var(--sidebar-linechart-svg);
}

.sidebar-flip-svg path {
  fill: var(--sidebar-flip-svg);
}

.modal-minus-svg {
  fill: var(--setting-modal-header-minus-svg);
}

.modal-header-close-svg {
  fill: var(--setting-modal-header-close-svg);
}

.chart-header-dots-svg {
  fill: var(--chart-header-dots-svg);
}

.opacity-btn {
  min-width: 70px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--modal-header-svg-background);
}

.select-light-theme .opacity-btn {
  background: #009856;
}

.select-medium-theme .opacity-btn {
  background: #283142;
}

.select-dark-theme .opacity-btn {
  background: #ffffff19;
}

.sidebar-main .sidebar-opacity-btn {
  background-color: var(--header-svg-background) !important;
}

#setting_svg {
  fill: yellow;
}

.modal-wrapper .model-header-right p {
  font-size: 10px;
  line-height: 15px;
  font-family: "Poppins-Light";
  color: #ffffff;
  margin: 0 0 4px 0;
}

.body-p {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  border-top: 1px solid var(--modal-border);
}

.chart-body .graph-img {
  width: 100%;
  height: 855px;
  object-fit: cover;
}

.main-chart-box {
  /* height: 819px; */
  /* height: 912px; */
}

.opacity-btn .MuiSlider-sizeMedium {
  /* color: #3a6dd6; */
  color: var(--modal-opacity-slider);
  padding: 0;
}

.select-light-theme .MuiSlider-sizeMedium {
  color: #ffffff;
}

.select-medium-theme .MuiSlider-sizeMedium {
  color: #3a6dd6;
}

.select-dark-theme .MuiSlider-sizeMedium {
  color: #a5ff7c;
}

.opacity-btn .MuiSlider-thumb {
  width: 10px;
  height: 10px;
}

.opacity-btn .css-frv3qg-MuiSlider-thumb:hover,
.opacity-btn .css-frv3qg-MuiSlider-thumb.Mui-active {
  box-shadow: none;
}

.opacity-btn .css-14pt78w-MuiSlider-rail {
  height: 2px;
}

.opacity-btn .css-1gv0vcd-MuiSlider-track {
  height: 2px;
}

/* .css-1diafny-MuiSlider-root {
  color: var(--modal-opacity-slider) !important;
} */

.opacity-btn .css-q51it5 {
  display: flex;
}

.content-wrapper {
  width: 100%;
}

.recent-index-wrapper {
  height: 100%;
  max-height: 470px;
  overflow: auto;
}

.recent-index-wrapper::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.recent-index-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--modal-sorting-inner-text-bg);
  border-radius: 10px;
}

.search-box {
  margin-top: 0;
  position: relative;
}

.searchWhite {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.content-wrapper .form-group .form-control input {
  background: #1d2330;
  padding: 7.5px 20px 7.5px 20px;
}

.index-input-grp {
  position: relative;
  margin: 20px 20px 0 0;
  width: 260px;
  cursor: pointer;
}

.market-list-main .index-input-grp {
  margin: 20px 20px 0 0;
  width: 200px;
}

.label-no {
  width: 40px;
  height: 38px;
  /* background: #42506f; */
  background: var(--modal-sorting-inner-text-bg);
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Light";
  color: #ffffff;
}

.add-script-label-no {
  height: 39px;
}

.index-input-grp:hover .label-no-favorite {
  display: block;
}

.label-no-favorite {
  width: 40px;
  height: 38px;
  /* background: #42506f; */
  /* background: var(--modal-sorting-inner-text-bg); */
  background: var(--modal-body-background);
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Light";
  /* color: var(--setting-model-minute-button-text); */
  color: #ffffff;
  text-align: center;
  padding-top: 6px;
  display: none;
}

.select-dark-theme .label-no-favorite .favorite-icon,
.select-medium-theme .label-no-favorite .favorite-icon {
  /* color: var(--dark-text); */
  color: var(--script-active-tab);
  cursor: pointer;
  fill: white;
}

.label-no-favorite .favorite-icon.favorite {
  color: var(--script-active-tab);
  /* color: #ff0000; */
}

.index-input-grp .form-group .form-control input {
  background: var(--modal-body-background);
  padding: 7.5px 8.5px 7.5px 54px;
}

.index-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.script-list-main {
  max-height: 245px;
  overflow: auto;
}

.script-list-main::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.script-list-main::-webkit-scrollbar-thumb {
  background-color: var(--modal-sorting-inner-text-bg);
  border-radius: 10px;
}

.index-input-wrapper .index-input-grp:nth-child(2n + 2) {
  margin: 20px 0px 0 0;
}

/* .three-dot:hover .li-hover {
    display: block;
} */

.head-menu {
  position: absolute;
  /* background: #283142; */
  background: var(--chart-dropdown-background);
  /* width: 150px; */
  width: "fit-content";
  right: 3%;
  top: 31px;
  /* height: 195px; */
  height: auto;
  z-index: 9;
  border-radius: 6px;
  /* box-shadow: 0px 10px 10px #0000005e; */
  padding: 15px;
  display: none;
}

.watchList-menu {
  width: 200px;
  height: fit-content;
  max-height: 200px;
  overflow: auto;
}

.watchList-menu::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.watchList-menu::-webkit-scrollbar-track {
  /* background: gray; */
}

.watchList-menu::-webkit-scrollbar-thumb {
  background-color: var(--modal-sorting-inner-text-bg);
  border-radius: 10px;
}

.open-order-head-menu {
  height: fit-content;
}

.head-menu ul {
  padding: 0;
}

.head-menu ul li {
  /* background: #1d2330; */
  background: var(--chart-dropdown-button);
  border-radius: 4px;
  /* color: #ffffff; */
  color: var(--dark-text) !important;
  margin-bottom: 5px;
  padding: 2.5px 15px;
  height: 30px;
  font-size: 18px;
  line-height: 25px;
  font-family: "Poppins-Regular";
}

.watchList-menu ul li {
  margin-bottom: 5px;
  padding: 3px 3px;
  width: 100%;
  height: fit-content;
  font-size: 15px;
  line-height: 22px;
}

.head-menu ul li:last-child {
  margin-bottom: 0;
}

/* .three-dot:hover .head-menu {
    display: block;
} */

.two-chart-box .main-chart-box {
  max-height: 400px !important;
  margin-bottom: 25px;
}

.chart-body {
  position: relative;
}

.chart-body .cus-hover {
  display: none;
  position: absolute;
  top: 9px;
  left: 14px;
  color: #5a76b4;
  font-size: 20px;
  line-height: 28px;
  z-index: 9;
}

.chart-body:hover .cus-hover {
  display: block;
}

.dash-grid .chart-main {
  display: flex;
  flex-wrap: wrap;
  margin-left: -7.5px;
  margin-right: -7.5px;
  margin-top: 0px;
}

.main-content-sec .dashboard-chart-main {
  height: calc(100vh - 80px);
  overflow: hidden;
  border-bottom: 2px solid var(--header-border);
}

.main-content-sec .tracking-dashboard-chart-main {
  height: calc(100vh - 130px);
}

.small-chart-wrap {
  padding: 0 7.5px;
  width: 25%;
  margin-bottom: 15px;
}

.open-order-chart {
  background-color: var(--chart-background);
  border-radius: 5px;
}

.small-chart-box {
  /* background: #181D28; */
  /* height: 100%; */
}

.active-chart {
  /* border: 5px solid transparent; */
  /* border-image: linear-gradient(
    to bottom right,
    #b827fc 0%,
    #2c90fc 25%,
    #b8fd33 50%,
    #fec837 75%,
    #fd1892 100%
  ) !important; */
  /* border-image-slice: 1 !important; */
  border-radius: 10px;
}

.small-chart-box .chart-header-main {
  padding: 5px 8px 5px 12px;
}

.small-chart-box .chart-header-left .chart-text {
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  margin: 0 10.6px 0 0;
}

.small-chart-box .chart-header-time .chart-updated-time-text {
  font-size: 8px;
  line-height: 15px;
  color: var(--dark-text);
}

.small-chart-box .chat-header-right .chart-time-sec {
  display: none;
}

.small-chart-box .chart-header-left .red-down-aroow {
  width: 9.33px;
  height: 8px;
  margin-left: 5.5px;
  font-family: "Poppins-Regular";
}

.small-chart-box .chat-header-right .red-btn-main .buy-btn {
  font-size: 12px;
  line-height: 15px;
  height: 20px;
  min-width: 60px;
  /* margin: 0px 8px 0px 0px; */
}

.small-chart-box .round-btn-main .download-btn {
  margin-right: 8px;
}

.small-chart-box .round-btn-main .round-btn {
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  background: #42506f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-chart-box .round-btn-main .round-btn img {
  width: 10px;
  height: 10px;
  object-fit: cover;
}

.small-chart-box .dots-img {
  width: 6px;
  height: 18px;
}

.small-chart-box .dots-img {
  width: 6px;
  height: 18px;
}

.small-chart-box .chart-body .cus-hover {
  font-size: 12px;
  line-height: 17px;
}

.modal-header-sec .chart-header-main {
  background: var(--chart-header-background) !important;
}

.modal-header-sec .chart-header-left .chart-text {
  font-size: 20px;
  line-height: 28px;
}

.modal-header-sec .chart-header-time .chart-updated-time-text {
  font-size: 14px;
  line-height: 22px;
  color: var(--dark-text);
}

.select-dark-theme
  .modal-header-sec
  .chart-header-time
  .chart-updated-time-text,
.select-medium-theme
  .modal-header-sec
  .chart-header-time
  .chart-updated-time-text {
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.select-light-theme
  .modal-header-sec
  .chart-header-time
  .chart-updated-time-text {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.modal-header-sec .red-btn-main .buy-btn {
  height: 30px;
  min-width: 70px;
  margin: 0px 20px 0px 20px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 23px;
}

.css-69ys7f:focus-visible {
  outline: none;
}

.close-img {
  margin-left: 15px;
  width: 20px;
  height: 20px;
  object-fit: cover;
  display: none;
  cursor: pointer;
}

.modal-header-sec .close-img {
  display: block;
}

.chat-header-right .min-btn,
.chart-hover-sec .min-btn,
.modal-header-sec .chat-header-right .chart-time-sec {
  display: none;
}

.main-chart-box .buy-min-btn-sec,
.small-chart-box .buy-min-btn-sec {
  margin: 0 15px;
}

.main-chart-box .buy-min-btn-sec:hover .min-btn,
.chart-hover-sec .buy-min-btn-sec:hover .min-btn,
.small-chart-box .buy-min-btn-sec:hover .min-btn,
.modal-header-sec .buy-min-btn-sec:hover .min-btn {
  display: inline-flex;
}

.main-chart-box .buy-min-btn-sec:hover .buy-sell-btn,
.chart-hover-sec .buy-min-btn-sec:hover .buy-sell-btn,
.small-chart-box .buy-min-btn-sec:hover .buy-sell-btn,
.modal-header-sec .buy-min-btn-sec:hover .buy-sell-btn {
  display: none;
}

.tabs-content-panel,
.tabpanel-simple {
  padding: 30px 20px;
  position: relative;
}

.tab-content-main .tabs-content-title {
  /* color: #ffffff; */
  color: var(--dark-text);
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Medium";
  /* padding: 9px 17px; */
  width: 175px;
  min-height: 42px;
  padding: 0;
  text-transform: capitalize;
  /* background: #181d28; */
  background: var(--modal-header-button-background) !important;
  /* width: calc(100% / 4); */
}

.tab-content-main .tabs-content-title.Mui-selected {
  /* background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0%
    0%; */
  background: var(--modal-header-button-active-background) !important;
  color: var(--light-text);
}

.tabs-content-top .MuiTabs-indicator {
  display: none;
}

.tabs-content-top {
  background: var(--report-order-button-background);
  /* border-radius: 6px; */
  min-height: 42px !important;
}

.performance-report-top-btn {
  border-radius: 6px;
}

.tab-border-content {
  border-bottom: none !important;
  height: 42px;
}

.report-tab-content-main .tab-border-content {
  height: 50px;
}

.modal-sub-title {
  font-size: 18px;
  line-height: 25px;
  font-family: "Poppins-Regular";
}

.chart-view-sec {
  display: flex;
  align-items: center;
}

.modal-ul-li .modal-ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  align-items: center;
  margin: 0 0 0 15px;
}

.modal-ul-li .chart-view-btn-ul {
  margin: 0;
}

.modal-ul-li .modal-ul .modal-li {
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  /* padding: 0; */
  width: 100px;
  height: 30px;
  border: 1px solid var(--modal-button-border);

  background: var(--modal-intraday-button--background);
  margin: 0 10px 0 0;
  border-radius: 6px;
  justify-content: center !important;
  cursor: pointer;
}

.modal-ul-li .modal-ul .modal-li.active,
.time-inner-li.active,
.intraday-sec .time-frame-li.active,
.report-modal-sec .time-frame-li.active {
  background: var(--modal-intraday-button-active-background);
  border-color: transparent;
  color: var(--common-text);
}

.select-dark-theme .modal-ul-li .modal-ul .modal-li.active {
  background: #a5ff7c;
}

.select-dark-theme .report-modal-sec-cus .time-frame-li.active {
  background: #a5ff7c;
}

.okay-sec {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.okay-sec .primary-btn-main .okay-btn {
  width: 75px;
  font-size: 17px;
  line-height: 27px;
}

.time-frame-sec {
  margin: 15px 0;
}

.time-frame-left {
  width: 75%;
  /* height: 105px; */
}

.time-frame-right {
  width: 25%;
  /* height: 105px; */
  display: flex;
  justify-content: flex-end;
}

.time-frame-ul {
  display: flex;
  padding: 0 !important;
  flex-wrap: wrap;
}

.time-btn-sec .time-frame-ul-li .time-frame-ul .time-frame-li {
  font-size: 10px;
  line-height: 15px;
  font-family: "Poppins-Regular";
  width: 44px;
  height: 20px;
  border: 1px solid var(--modal-button-border);
  color: var(--dark-text);
  margin: 0 6px 0 0;
  border-radius: 6px;
  justify-content: center !important;
  padding: 0;
  cursor: pointer;
}

.time-btn-sec .time-frame-ul-li .time-frame-ul .time-frame-li.active {
  /* color: #ffff; */
  color: var(--light-text);
}

.time-btn-sec
  .time-frame-ul-li
  .time-frame-ul
  .time-frame-dark-text-color.active {
  color: #ffff;
  /* color: var(--light-text); */
}

.time-btn-sec .time-frame-ul-li .time-frame-ul .time-frame-li:last-child {
  margin: 0 0 0 0;
}

.time-btn-sec .time-frame-ul-li .time-frame-ul {
  margin: 0 0 0 12px;
}

.time-frame-left .time-frame-ul .time-frame-li {
  border: 1px solid var(--modal-button-border);
  width: max-content;
  border-radius: 6px;
  padding: 6.5px 10px;
  height: 30px;
  font-size: 12px;
  line-height: 17px;
  margin: 0 10px 12px 0px;
  /* margin: 0 12px 12px 0px; */
  cursor: pointer;
  font-family: "Poppins-Regular";
}

.positional-sec .time-frame-ul-li .time-frame-ul .time-frame-li:nth-child(4) {
  margin-right: 12px;
}

.time-btn-sec {
  display: none;
}

.time-frame-ul .time-frame-li.active {
  /* border-color: transparent !important; */
  border-color: var(--modal-active-button-border) !important;
}

.time-frame-ul .chart-setting-timeframe.active {
  background-color: var(--modal-active-button-border);
}

.time-frame-ul .time-frame-li.active .time-btn-sec {
  display: inline-flex;
}

.time-frame-ul .chart-setting-timeframe.active .timeframe-title {
  color: #ffffff;
}

.select-dark-theme
  .time-frame-ul
  .chart-setting-timeframe.active
  .timeframe-title {
  color: #000000;
}

.time-frame-content {
  display: flex;
  margin: 8px 0 0 0;
}

.time-frame-right .index-input-grp {
  margin: 0px 0px 0 0;
  width: 118px;
}

.time-frame-right .label-no {
  width: 28px;
  height: 28px;
  background: var(--modal-togel-button-active);
  color: var(--common-text);
  right: 0;
  left: initial;
  top: 1px;
  cursor: pointer;
}

.time-frame-right .form-control input,
.time-frame-right .form-group .form-control input::placeholder {
  font-size: 14px;
  line-height: 20px;
  padding: 4px 28px 4px 8.5px !important;
  font-family: "Poppins-Regular";
  /* color: var(--dark-text) !important; */
}

.MuiOutlinedInput-input.MuiSelect-select {
  color: var(--dark-text) !important;
  padding: 12.5px 14px;
}

.index-select
  .drop-form-control
  .select-form-control
  .MuiOutlinedInput-input.MuiSelect-select {
  color: var(--select-text) !important;
}
.index-select .drop-form-control .form-control .MuiSvgIcon-root {
  fill: var(--select-text) !important;
}

.time-frame-right .form-group .form-control input::placeholder {
  color: #1a55d5 !important;
}

.style-sec .modal-ul-li .modal-ul .modal-li {
  width: 130px;
  justify-content: flex-start !important;
  padding: 7.5px 8px;
  align-items: center;
  cursor: pointer;
}

.modal-chart-img {
  width: 15px;
  height: 15px;
  object-fit: cover;
  margin-right: 6.5px;
}

.style-sec .modal-ul-li .modal-ul {
  margin: 0 0 0 0;
}

.moda-list-p {
  display: flex;
  align-items: center;
}

.intraday-sec .time-frame-ul .time-frame-li {
  width: 70px;
  justify-content: center;
  padding: 6.5px 7px;
}

.tab-panel-details .tab-sub-title {
  font-size: 16px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  margin-bottom: 10.8px;
}

.com-toggle-btn .alignment-toggle-group {
  background-color: #283142;
  border-radius: 4px;
}

.alignment-toggle-group .alignment-toggle-value {
  color: #ffff;
  width: 36px;
  height: 20px;
  font-size: 10px;
  line-height: 15px;
  font-family: "Poppins-Regular";
  text-transform: capitalize;
  padding: 9px;
  background: var(--modal-togel-button);
}

.alignment-toggle-group .alignment-toggle-value.Mui-selected {
  color: var(--light-text);
  background: var(--modal-togel-button-active);
}

.alignment-toggle-group .alignment-toggle-value.Mui-selected:hover {
  color: var(--light-text);
  background: var(--modal-togel-button-active) !important;
}

.alignment-toggle-group .alignment-toggle-value:hover {
  color: #ffffff;
  background: var(--modal-togel-button) !important;
}

.com-toggle-btn {
  line-height: 0;
}

.auto-square-toggle {
  display: flex;
}

.auto-square-toggle .time-setting-btn {
  margin-left: 10px;
  padding: 0;
}

.auto-square-toggle .time-setting-btn .time-setting-img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.select-medium-theme .auto-square-toggle .time-setting-btn .time-setting-img,
.select-dark-theme .auto-square-toggle .time-setting-btn .time-setting-img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(19%) hue-rotate(306deg) brightness(104%) contrast(108%);
}

.switch-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.switch-box {
  width: 165px;
  margin-bottom: 15px;
}

.drop-sec {
  margin-left: 12px;
  display: flex;
}

.drop-sec .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: var(--text-field-border) !important;
  border-width: 1px;
}

.drop-sec .css-1nrlq1o-MuiFormControl-root {
  margin: 0 !important;
}

.drop-sec .MuiInputBase-formControl:hover,
.drop-sec .MuiInputBase-formControl {
  border: 1px solid var(--modal-button-border) !important;
}

.select-light-theme .drop-sec .MuiInputBase-formControl:hover,
.select-light-theme .drop-sec .MuiInputBase-formControl {
  border: 1px solid #009856 !important;
}

.select-medium-theme .drop-sec .MuiInputBase-formControl:hover,
.select-medium-theme .drop-sec .MuiInputBase-formControl {
  border: 1px solid #42506f !important;
}

.select-dark-theme .drop-sec .MuiInputBase-formControl:hover,
.select-dark-theme .drop-sec .MuiInputBase-formControl {
  border: 1px solid #191919 !important;
}

.select-dark-theme .tool-thickness-box .MuiInputBase-formControl {
  border: 1px solid var(--modal-opacity-slider) !important;
}

.select-dark-theme .tool-thickness-box .MuiInputBase-formControl,
.select-dark-theme .tool-thickness-box .MuiInputBase-formControl:hover {
  border: 1px solid #a5ff7c !important;
  
}

/* .drop-sec .MuiInputBase-formControl:hover ,.MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--modal-button-border) !important;
} */

.drop-sec .MuiInputBase-formControl {
  color: #ffffff;
}

.data-display-sec .data-display-drop .MuiInputBase-formControl {
  width: 60px;
}

.select-light-theme .data-display-drop-cus {
  background-color: #009856;
  border: 1px solid #009856;
  border-radius: 5px;
  border: none;
}

.select-medium-theme .data-display-drop-cus {
  background-color: #8789fb;
  border: 1px solid #8789fb;
  border-radius: 5px;
  border: none;
}

.select-dark-theme
  .data-display-drop-cus
  .MuiOutlinedInput-input.MuiSelect-select {
  color: black !important;
}

.select-light-theme
  .data-display-drop-cus
  .MuiOutlinedInput-input.MuiSelect-select {
  color: white !important;
}

.select-dark-theme .data-display-drop-cus {
  background-color: #a5ff7c;
  border: 1px solid #a5ff7c;
  border-radius: 5px;
  border: none !important;
  color: black !important;
}

.drop-sec .MuiSelect-icon {
  /* fill: #ffffff; */
  fill: var(--dark-text);
  width: 13px;
  height: 13px;
  object-fit: cover;
  top: 8px;
}

.drop-sec .MuiSelect-select {
  font-size: 10px;
  line-height: 17px;
  padding: 6.5px 10px;
  font-family: "Poppins-Regular";
}

.drop-sec .MuiSelect-nativeInput {
  height: 30px;
  outline: none;
}

.drop-sec .MuiInputBase-input {
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Regular";
}

/* .drop-sec .MuiMenu {
background: #181D28;
color: #FFFFFF !important;
padding-top: 0 !important;
} */
.drop-sec
  .MuiFormControl-root
  .MuiInputBase-formControl
  .MuiOutlinedInput-input {
  padding-right: 23.36px;
}

.MuiAutocomplete-popper .MuiPaper-root {
  background: none;
  border-radius: 8px;
  box-shadow: none;
  margin-top: 5px;
}

.MuiAutocomplete-popper .MuiPaper-root ul,
.MuiAutocomplete-noOptions {
  border-radius: 8px !important;
}

.MuiPaper-elevation ul {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  /* background: #181d28 !important; */
  /* background: var(--modal-body-background) !important; */
  border: 1px solid var(--modal-button-border) !important;
  border-radius: 4px !important;
  /* color: #ffffff; */
  color: var(--dark-text) !important;
}

/* background-color: var(--modal-header-svg-background); */
/* background-color: var(--modal-intraday-button--background); */

.select-light-theme .MuiPaper-root.MuiPopover-paper {
  background-color: #98d19a;
  margin-top: 5px;
}

.select-medium-theme .MuiPaper-root.MuiPopover-paper {
  background-color: #283142;
  margin-top: 5px;
}

.select-dark-theme .MuiPaper-root.MuiPopover-paper {
  background-color: #000000;
  margin-top: 5px;
}

.MuiPaper-elevation ul > li {
  font-size: 14px;
  line-height: 17px;
  font-family: "Poppins-Regular";
}

.data-display-sec .tab-sub-title {
  margin-bottom: 0;
}

.data-display-sec {
  display: flex;
  align-items: center;
}

.tab-panel-details .reload-btn {
  min-width: 24px;
  height: 24px;
  /* background: #42506f; */
  background: var(--modal-relode-button-background);
  border-radius: 4px;
  position: absolute;
  top: 20px;
  right: 15px;
}

.tab-panel-details .reload-btn:hover {
  /* background: #42506f; */
  background: var(--modal-relode-button-background);
}

.reload-img {
  width: 11px;
  height: 11px;
}

.color-setting .modal-sub-title {
  margin-bottom: 14px;
}

.chart-color-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.chart-color-box {
  width: 270px;
  height: 100%;
  padding: 12px 15px;
  border: 1px solid var(--modal-button-border);
  border-radius: 6px;
}

.chart-color-box .chart-box {
  display: flex;
  align-items: center;
}

.chart-color-box .tab-sub-title {
  margin-bottom: 0;
}

.color-chart-img {
  margin-right: 12px;
}

.color-pick {
  width: 30px;
  height: 20px;
  border-radius: 4px;
}

.lime {
  background: #3ad688;
}

.red-bg {
  background: #ff0000;
}

.yellow-bg {
  background: #ccd63a;
}

.skublue-bg {
  background: #3acbd6;
}

.green-bg {
  background: #008000 !important;
}

.buy-btn-bg {
  background: #008000 !important;
}

.purple-bg {
  background: #916aff !important;
}

.blue-bg {
  background: #3a6dd6;
}

.pink-bg {
  background: #ff0078;
}

.chart-color-box {
  display: flex;
  justify-content: space-between;
  margin: 0 20px 20px 0;
}

.color-sec {
  display: flex;
  margin-bottom: 6px;
  /* justify-content: flex-end; */
  align-items: center;
}

.color-box .color-sec:last-child {
  margin-bottom: 0px;
  /* height: 20px; */
}

.color-box {
  /* width: 96px; */
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}

.color-box-inline {
  flex-direction: row;
  align-items: center;
}

.color-box-inline .color-sec {
  margin: 0;
}

.color-box .tab-sub-title {
  font-size: 12px;
  line-height: 17px;
  /* margin: 0 8px 0 0; */
  text-align: left;
  min-width: 59.59px;
}

.chart-color-wrapper .chart-color-box:nth-child(2n + 2) {
  margin-right: 0;
}

.crosshair-sec {
  padding: 12px 15px;
  border: 1px solid var(--setting-modal-button-border);
  border-radius: 6px;
  display: flex;
  /* margin: 0 20px 20px 0; */
  width: 100%;
  /* justify-content: space-between; */
  align-items: center;
}

.crosshair-thickness-sec {
  width: unset;
  justify-content: flex-start;
  align-items: flex-start;
}

.crosshair-sec .color-sec .tab-sub-title {
  /* color: #5a76b4; */
  color: var(--text-color);
  margin: 0 12px 0 8px;
}

.crosshair-sec .color-sec,
.crosshair-sec .tab-sub-title {
  margin-bottom: 0;
}

.chart-color-wrapper .crosshair-sec:last-child,
.chart-color-wrapper .crosshair-sec:nth-last-child(2) {
  margin-bottom: 0;
}

/* .MuiPaper-elevation ul{
    background: #000 !important;
    border: 1px solid #42506F;
    border-radius: 4px;
    margin-top: 4px;
} */
/* .thickness-drop {
  margin-left: 0;
} */

.thickness-drop .MuiSelect-icon {
  display: none;
}

.thickness-drop .MuiSelect-select {
  line-height: 9px;
  padding: 1.38px 8px !important;
}

.thickness-drop.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 8.5px !important;
}

.thickness-drop .MuiSelect-select img {
  width: 14px;
  /* height: 1px; */
}

.modal-body-close {
  display: none;
}

.shortcuts-key-btn {
  width: 100%;
  height: 50px;
  padding: 12.5px 45px 12.5px 15px;
  /* background: #283142; */
  background: var(--modal-general-shortcut-header);
  border-radius: 6px;
}

.shortcuts-sec .plus-btn {
  min-width: 30px;
  height: 30px;
  border-radius: 6px;
  /* background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0%
    0%; */
  background: var(--gradient-button);
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.shortcuts-key-btn {
  position: relative;
}

.shortcuts-key-btn .key-title {
  font-size: 18px;
  line-height: 25px;
  font-family: "Poppins-Medium";
}

.li-button-sec .secondary-btn-main .secondarybtn-btn {
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Regular";
  /* background: #283142; */
  background: var(--gradient-button);
  width: 100%;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  text-transform: capitalize;
}

.select-dark-theme .li-button-sec .secondary-btn-main .secondarybtn-btn {
  color: #000000;
}

.secondary-btn-main .shortcut-btn {
  /* width: 30px; */
  height: 30px;
  min-width: 30px;
  width: 100%;
  color: var(--dark-text);
}

.secondary-btn-main .shortcut-btn:hover {
  background-color: var(--modal-shortcut-button-hover);
}

.shortcuts-ul {
  margin-top: 20px;
  flex-wrap: wrap;
  max-height: 300px;
  height: 100%;
  overflow: auto;
}

.shortcuts-ul::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.shortcuts-ul::-webkit-scrollbar-track {
  /* background: gray; */
}

.shortcuts-ul::-webkit-scrollbar-thumb {
  background-color: var(--modal-sorting-inner-text-bg);
  border-radius: 10px;
}

.shortcuts-ul,
.shortcuts-li,
.edit-delete-sec {
  display: flex;
  align-items: center;
}

.shortcuts-li .li-button-sec {
  display: flex;
}

.shortcuts-ul .shortcuts-li {
  justify-content: space-between;
  width: 100%;
  margin: 0 0 15px 0;
  padding-right: 10px;
}

.li-button-sec .edit-delete-sec {
  margin: 0 0 0 33.9px;
}

.edit-delete-sec .shortcut-edit-btn {
  margin: 0 10px 0 0;
}

.shortcut-img img {
  width: 12px;
  height: 12px;
}

.li-title-sec .li-title {
  /* color: #5a76b4; */
  color: var(--text-color);
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Regular";
}

.shortcuts-ul .shortcuts-li:last-child {
  margin: 0 0 0 0;
}

.edit-shortcuts-wrapper .cus-input-grp {
  width: 100%;
  margin: 0 0 30px 0;
}

.add-btn-sec {
  display: flex;
}

.primary-btn-main .edit-sortcuts-btn {
  width: 181px;
  height: 50px;
}

.add-btn-sec .edit-btn {
  margin: 0 20px 0 0;
}

.replace-wrapper .key-combination-text {
  font-size: 18px;
  line-height: 27px;
  font-family: "Poppins-Light";
  text-align: center;
  color: var(--dark-text);
  text-align: center;
  margin: 0 0 20px 0;
}

/* .replace-wrapper,.replace-wrapper .primary-btn-main,.replace-wrapper .primary-btn-main .login-btn{
    width: 100% !important;
    text-align: center;
} */
/* .replace-wrapper .add-btn-sec{
    max-width: 100%;
} */
.replace-wrapper .primary-btn-main .login-btn {
  max-width: 100%;
  margin: 30px 0 0 0;
}

.ctrl-shift-sec .ctrl-bg {
  /* background: #283142; */
  background: var(--modal-replace-key-background);
  padding: 8.5px 16px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Light";
  color: var(--dark-text) !important;
  color: #ffffff;
}

.ctrl-shift-sec {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ctrl-shift-sec .plus-text {
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Light";
  /* color: #5a76b4; */
  color: var(--analysis-table-header-text);
  margin: 0 10px;
}

.replace-wrapper .primary-btn-main,
.replace-wrapper {
  width: 100%;
}

.color-input input {
  padding: 0;
  width: 30px;
  height: 20px;
}

.color-input input[type="color"] {
  -webkit-appearance: none;
  /* border: none; */
  width: 30px;
  height: 20px;
  border-radius: 4px;
  outline: none;
}

.color-input input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 4px;
  border: none;
}

.color-input input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 4px;
}

.primary-btn-main .index-btn {
  width: 118px;
}

.primary-btn-main .header-btn {
  width: 75px;
  font-size: 16px;
  line-height: 20px;
}

.table-sec {
  margin-top: 15px;
}

.table-sec .MuiDataGrid-columnHeaderTitle {
  /* color: #5a76b4; */
  color: var(--table-header-text);
  font-size: 18px;
  line-height: 25px;
  font-family: "Poppins-Light";
  text-align: center !important;
}

.table-sec .MuiDataGrid-columnHeaders {
  border: 1px solid var(--table-border) !important;
  background: var(--table-header-background);
  border-radius: 10px;
  min-height: 50px !important;
  max-height: 50px !important;
  text-align: center !important;
}

.table-sec .MuiDataGrid-withBorderColor {
  border: none;
  color: var(--dark-text);
  /* border-bottom: 1px solid var(--table-border); */
  /* padding: 0; */
  /* background: var(--analysis-table-header-background); */
}

.table-sec .css-12u49z-MuiDataGrid-root {
  border: none;
}

.table-sec .MuiDataGrid-columnSeparator {
  display: none;
  min-height: 50px !important;
  text-align: center;
}

.table-sec
  .css-12u49z-MuiDataGrid-root
  .MuiDataGrid-columnHeaderTitleContainer {
  /* justify-content: flex-start !important; */
}

.table-sec
  .MuiDataGrid-withBorderColor
  .MuiDataGrid-columnHeader--alignRight
  .MuiDataGrid-columnHeaderDraggableContainer,
.table-sec
  .MuiDataGrid-withBorderColor
  .MuiDataGrid-columnHeader--alignRight
  .MuiDataGrid-columnHeaderTitleContainer {
  flex-direction: unset !important;
}

.table-sec .css-yrdy0g-MuiDataGrid-columnHeaderRow {
  min-height: 50px;
  max-height: 50px;
  height: 50px;
  text-align: center;
}

/* .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:first-child{
    min-width: 9.5% !important;
    max-width: 9.5% !important;
}
.MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2){
    min-width: 57.5% !important;
    max-width: 57.5% !important;
}
.MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3){
    min-width: 30% !important;
    max-width: 30% !important;
}
.MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4){
    min-width: 33.75% !important;
    max-width: 33.75% !important;
}
.MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5){
    min-width: 30% !important;
    max-width: 30% !important;
}
.MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6){
    min-width: 43.25% !important;
    max-width: 43.25% !important;
}
.MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(7){
    min-width: 6.25% !important;
    max-width: 6.25% !important;
}
.MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(8){
    min-width: 6.25% !important;
    max-width: 6.25% !important;
}

.MuiDataGrid-row .MuiDataGrid-cell:first-child{
    min-width: 4.17% !important;
    max-width: 4.17% !important;
}
.MuiDataGrid-row .MuiDataGrid-cell:nth-child(2){
    min-width: 22.549% !important;
    max-width: 22.549% !important;
}
.MuiDataGrid-row .MuiDataGrid-cell:nth-child(3){
    min-width: 11.84% !important;
    max-width: 11.84% !important;
}
.MuiDataGrid-row .MuiDataGrid-cell:nth-child(4){
    min-width: 13.35% !important;
    max-width: 13.35% !important;
}
.MuiDataGrid-row .MuiDataGrid-cell:nth-child(5){
    min-width: 11.92% !important;
    max-width: 11.92% !important;
}
.MuiDataGrid-row .MuiDataGrid-cell:nth-child(6){
    min-width: 16.9% !important;
    max-width: 16.9% !important;
}
.MuiDataGrid-row .MuiDataGrid-cell:nth-child(7){
    min-width: 2.49% !important;
    max-width: 2.49% !important;
}
.MuiDataGrid-row .MuiDataGrid-cell:nth-child(8){
    min-width: 2.49% !important;
    max-width: 2.49% !important;
} */
.table-sec .css-12u49z-MuiDataGrid-root .MuiDataGrid-columnHeader {
  padding: 12px 20px !important;
  height: 50px !important;
  position: relative;
}

.table-sec .MuiDataGrid-withBorderColor .MuiDataGrid-columnHeader::after {
  content: "";
  /* border-right: 1px solid #707070; */
  width: 1px;
  /* background: #283142; */
  background: var(--table-border) !important;
  height: 100%;
  position: absolute;
  right: 0;
}

.table-sec
  .MuiDataGrid-withBorderColor
  .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover
  .MuiDataGrid-sortIcon,
.table-sec .MuiDataGrid-sortIcon,
.MuiDataGrid-menuList
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root
  .MuiListItemIcon-root,
.MuiDataGrid-menuIcon .MuiSvgIcon-fontSizeSmall {
  fill: var(--dark-text);
  color: #ffffff !important;
}

.table-sec .css-12u49z-MuiDataGrid-root .MuiDataGrid-cell--textRight {
  justify-content: flex-start;
}

.table-sec .green-buy-btn {
  width: 76px;
  height: 25px;
  background: #008000;
  border-radius: 25px;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  text-transform: capitalize;
}

.table-sec .red-buy-btn {
  width: 76px;
  height: 25px;
  background: #ff0000;
  border-radius: 25px;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  text-transform: capitalize;
}

.table-sec .red-buy-btn:hover {
  background: #ff0000;
}

.table-sec .green-buy-btn:hover {
  background: #008000;
}

.table-img {
  width: 51.74px;
  height: 19.29px;
}

.table-sec .table-gray-btn {
  min-width: 26px;
  max-width: 26px;
  height: 26px;
  /* background: #283142; */
  background: var(--table-action-button-background);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 14px 0 0;
  box-shadow: none;
  border: none;
  outline: none;
  cursor: pointer;
}

/* `&super-app-theme--header` {
  background-color: red !important
} */
.table-sec .table-gray-btn:hover {
  /* background: #283142; */
  background: var(--table-action-button-background-hover);
}

.table-gray-btn .action-btn-img {
  width: 15px;
  height: 15px;
}

.table-gray-btn .action-btn-icon {
  fill: #ffff;
}

.table-sec .MuiDataGrid-cell:last-child {
  justify-content: center !important;
}

/* .table-sec .css-12u49z-MuiDataGrid-root .MuiDataGrid-withBorderColor:last-child {
    border-bottom: none;
} */

.table-sec .css-rtrcn9-MuiTablePagination-root {
  /* color: #ffffff; */
}

.table-sec .MuiDataGrid-iconSeparator {
  display: none;
}

.MuiDataGrid-virtualScrollerRenderZone
  .MuiDataGrid-row
  .MuiDataGrid-cell:nth-last-child(2) {
  justify-content: center !important;
}

.css-12u49z-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-12u49z-MuiDataGrid-root .MuiDataGrid-cell:focus,
.css-12u49z-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-12u49z-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.table-sec
  .MuiDataGrid-main
  .MuiDataGrid-columnHeaders
  .MuiDataGrid-columnHeader:focus,
.table-sec
  .MuiDataGrid-main
  .MuiDataGrid-columnHeaders
  .MuiDataGrid-columnHeader:focus-within,
.table-sec .MuiDataGrid-main .MuiDataGrid-cell:focus,
.table-sec .MuiDataGrid-main .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.table-sec .MuiDataGrid-virtualScrollerContent {
  max-height: 660px !important;
}

/* .table-sec .MuiDataGrid-cell {
    min-height: 65px !important;
    max-height: 65px !important;
} */
.table-sec .MuiDataGrid-row {
  /* min-height: 60px !important;
  max-height: 60px !important; */
}

.table-sec .css-12u49z-MuiDataGrid-root .MuiDataGrid-columnHeader,
.css-12u49z-MuiDataGrid-root .MuiDataGrid-cell {
  padding: 0 20px;
}

/* / line chart css / */
.anychart-credits {
  display: none;
}

.anychart-ui-support {
  /* fill: #181d29; */
  /* border-radius: 0 0 5px 5px; */
  /* transform: scale(1.15,1); */
}

.custom_candlestick_chart,
.custom_line_chart,
.custom_area_chart {
  position: relative;
}

.custom_candlestick_chart:hover .chart_ohcl,
.custom_line_chart:hover .chart_ohcl,
.custom_area_chart:hover .chart_ohcl {
  opacity: 1;
}

.chart_ohcl {
  position: absolute;
  top: 4px;
  left: 7px;
  z-index: 2;
  opacity: 0;
  transition: 0.4s;
}

.main-chart-box .chart_ohcl .MuiTypography-caption {
  font-size: 20px;
  line-height: 28px;
  font-family: "Poppins-Regular";
}

.small-chart-box .chart_ohcl .MuiTypography-caption {
  font-size: 12px;
  line-height: 17px;
  font-family: "Poppins-Regular";
}

/* / Chart head css / */

.custom_btn {
  border-radius: 20px !important;
  padding: 1px 8px !important;
  font-size: 11px !important;
}

.dash-grid .grid-main {
  padding: 0 7.5px 0px 7.5px !important;
  margin-bottom: 15px !important;
}

.head-menu.active {
  display: block;
}

.MuiTablePagination-displayedRows {
  color: var(--dark-text);
}

.table-sec .MuiTablePagination-actions .MuiIconButton-colorInherit {
  width: 22px;
  height: 22px;
  background: var(--table-action-button-background);
  border-radius: 4px;
  color: var(--dark-text);
  margin: 0 10px;
}

.table-sec .MuiTablePagination-actions .MuiIconButton-colorInherit:hover {
  background: var(--table-action-button-background-hover);
}

.table-sec
  .MuiTablePagination-actions
  .MuiIconButton-colorInherit
  .MuiSvgIcon-root {
  fill: #ffffff;
}

.table-sec
  .MuiTablePagination-actions
  .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
  background-color: var(--table-action-button-background);
  color: var(--dark-text);
}

.alert-box .alert-modal-text {
  font-size: 22px;
  line-height: 33px;
  font-family: "Poppins-Regular";
  color: #ffffff;
}

.alert-wrapper {
  padding: 40.5px 26px;
}

.alert-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.alert-box .alert-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 30px 0;
}

.tag-sec {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.tag-sec .watch-sec {
  width: 92px;
  height: 32px;
  background: #3a6dd6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Regular";
  margin-left: 18px;
}

.tag-sec .minuts-sec {
  width: 117px;
  height: 32px;
  background: #42506f;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Regular";
  margin-left: 18px;
}

.alert-box .tag-sec:last-child {
  margin-bottom: 0;
}

.buy-label {
  padding: 5px 8px;
  background: #008000;
  border-radius: 6px 0 0 6px;
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
  font-family: "Poppins-Regular";
  height: 32px;
}

.buy-label-text {
  color: #ffffff;
}

.category-select-box .common-dropdown .buy-label-text {
  border-color: #008000;
}

.buy-sell-watch-main {
  display: flex;
}

.buy-sell-watch-label {
  display: flex;
  margin-left: 10px;
}

.value-lable {
  background: rgb(0 128 0 / 20%);
  border-radius: 0 6px 6px 0;
}

.bg-green {
  background: #008000;
}

.bg-red {
  background: #ff0000;
}

.bg-red-20 {
  background: rgb(255 0 0 / 20%);
}

.bg-blue {
  background: #3a6dd6 !important;
}

.bg-blue-20 {
  background: rgb(58 109 214 / 20%);
}

.order-header-main {
  /* background: #1e2431; */
  background: var(--chart-header-background);
  border-radius: 6px 6px 0 0;
  padding: 12px;
  /* height: 64px; */
  position: relative;
  display: flex;
  align-items: center;
}

.select-light-theme .order-header-main {
  background: #b6e5b8;
}

.select-medium-theme .order-header-main {
  background: #1e2431;
}

.select-dark-theme .order-header-main {
  background: #131111;
}

.round-chart {
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid var(--setting-modal-button-border);
  overflow: hidden;
  margin-right: 10px;
}

.round-chart .round-chart-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.stock-name-sec .chart-title {
  color: var(--dark-text);
}

.select-light-theme .stock-name-sec .chart-title {
  color: #000000;
}

.select-medium-theme .stock-name-sec .chart-title {
  color: #ffffff;
}

.select-dark-theme .stock-name-sec .chart-title {
  color: #ffffff;
}

.stock-name-sec .chart-text {
  /* color: #ffffff; */
  /* color: var(--dark-text); */
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Medium";
  margin-right: 10px;
}

.stock-name-sec {
  display: flex;
  /* padding-right: 85px; */
  padding-right: 50px;
}

.stock-name-sec .red-down-aroow {
  width: 9.33px;
  height: 8px;
  margin-left: 6px;
}

.time-entry-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time-entry-sec .time-entry-text {
  font-size: 9px;
  line-height: 14px;
  font-family: "Poppins-Regular";
  /* color: #ffffff; */
  color: var(--dark-text);
  margin-right: 10.3px;
}

.select-light-theme .time-entry-sec .time-entry-text {
  color: #000000;
}

.select-medium-theme .time-entry-sec .time-entry-text {
  color: #ffffff;
}

.select-dark-theme .time-entry-sec .time-entry-text {
  color: #ffffff;
}

.color-blue {
  /* color: #5a76b4 !important; */
  color: var(--text-color) !important;
}

.select-light-theme .color-blue {
  color: #000000 !important;
}

.select-medium-theme .color-blue {
  color: #5a76b4 !important;
}

.select-dark-theme .color-blue {
  color: #8a8a8a !important;
}

.select-light-theme .chart-hover-sec .tracking-hover-text {
  color: #ffffff !important;
}

.calender-img {
  width: 11px;
  height: 11px;
  object-fit: cover;
  margin-right: 6px;
}

.select-light-theme .calender-img {
  filter: invert(100%);
}

.select-light-theme .chart-hover-sec .calender-img {
  filter: invert(0);
}

.time-entry-left,
.time-entry-right {
  display: flex;
}

.time-entry-right .tool-icon-btn {
  border-radius: 5px;
  padding: 3px;
  margin-right: 8px;
}

.tool-icon-btn .tool-icon {
  width: 16px;
  height: 16px;
}

.select-light-theme .tool-icon-btn.active {
  background: transparent linear-gradient(90deg, #287c85 0%, #44a147 100%) 0% 0% no-repeat padding-box;
}

.select-medium-theme .tool-icon-btn.active {
  background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0% 0% no-repeat padding-box;
}

.select-dark-theme .tool-icon-btn.active {
  background: transparent linear-gradient(180deg, #a5ff7c 0%, #79ffd4 100%);
}

.select-light-theme .tool-icon-btn .tool-icon {
  filter: invert(75%);
}

.select-light-theme .tool-icon-btn.active .tool-icon {
  filter: invert(0%);
}

.select-dark-theme .tool-icon-btn.active .tool-icon {
  filter: invert(100%);
}

.order-header-right {
  width: 100%;
}

.header-watch-label {
  width: 60px;
  height: 24px;
  border-radius: 0 6px 0 6px;
  background: #3a6dd6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 17px;
  font-family: "Poppins-Regular";
  color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
}

.header-closed-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  border-radius: 0 0px 0 6px;
}

.header-closed-label {
  width: 60px;
  height: 24px;
  /* background: #916aff; */
  /* background: var(--chart-header-closed-background); */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 17px;
  font-family: "Poppins-Regular";
  color: #ffffff;
  border-radius: 0 0px 0 6px;
}

.header-closed-wrapper:hover .tag-name {
  display: none;
}

.header-tag-interval {
  display: none;
}

.header-closed-wrapper:hover .header-tag-interval {
  display: flex;
  cursor: pointer;
}

.header-cross-label {
  width: 24px;
  height: 24px;
  border-radius: 0 6px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #6345b8; */
  /* background: var(--chart-header-closed-svg-background); */
  cursor: pointer;
}

/* .main-content-sec {
    margin-right: 80px;
    padding: 25px;
    height: calc(100vh - 100px);
} */

.open-order-list-sec {
  /* position: fixed; */
  /* left: 0; */
  /* right: 85px; */
  /* bottom: 0; */
  /* height: calc(100vh - 427px); */
  background: #181d28;
}

.open-order-list-resizable {
  position: absolute !important;
  bottom: 50px;
  z-index: 1;
}

.open-order-list-resizable-left {
  margin-left: -45px;
}

.open-order-list-resizable .react-resizable-handle {
  position: absolute;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("../images/png/down-arrow.png");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-size: contain;
  filter: brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(7460%) hue-rotate(242deg) brightness(115%) contrast(101%);
}

.select-light-theme .open-order-list-resizable .react-resizable-handle {
  filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(16%) hue-rotate(309deg) brightness(100%) contrast(107%);
}

.open-order-list-resizable .react-resizable-handle-n {
  top: 0;
  -webkit-transform: rotate(225deg);
  transform: rotate(180deg);
}

.open-order-list-sec-right {
  position: fixed;
  left: 85px;
  right: 0px;
  bottom: 0;
  /* height: calc(100vh - 427px); */
  background: #181d28;
}

.open-order-header {
  height: 50px;
  /* background: #283142; */
  background: var(--order-list-header-background);
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.open-order-title .order-title-text {
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-family: "Poppins-Regular";
  /* color: #ffffff; */
  color: var(--dark-text);
}

.open-order-button .order-btn-bg {
  min-width: 30px;
  height: 30px;
  /* background: #181d28; */
  background: var(--order-list-header-button-background);
  border-radius: 4px;
  cursor: pointer;
}

.open-order-button {
  display: flex;
  align-items: center;
}

.max-btn {
  margin: 0 0 0 15px;
}

.open-order-body {
  /* height: calc(100vh - 488px); */
  background: var(--body-background) !important;
}

.open-order-body {
  /* padding: 30px; */
  padding-top: 10px;
  overflow: auto;
}

.open-order-body
  .MuiDataGrid-virtualScrollerRenderZone
  .MuiDataGrid-row
  .MuiDataGrid-cell:nth-last-child(2) {
  justify-content: flex-start !important;
}

.open-order-table-main .MuiDataGrid-root .MuiDataGrid-footerContainer {
  min-height: 35px;
}

.main-content-sec .open-order-list-filter {
  margin-bottom: 20px;
}

.open-order-body .table-sec {
  margin-top: 0;
}

.open-order-body .table-sec .MuiDataGrid-cell:last-child {
  justify-content: flex-start !important;
}

.open-order-body.active {
  height: calc(100vh - 160px);
  background: var(--dashboard-background);
}

.open-order-inner-body.active-mini {
  height: 0 !important;
  padding: 0 !important;
}

.table-sec
  .css-12u49z-MuiDataGrid-root
  .MuiDataGrid-withBorderColor:last-child::after {
  display: none;
}

.footer-sec {
  position: fixed;
  bottom: 0px;
  /* z-index: 999; */
  right: 50px;
}

.footer-sec.tracking-win-footer {
  bottom: 50px;
}

.footer-sec.hide-sidebar {
  right: 5px;
}

.footer-sec .MuiPaginationItem-rounded {
  /* color: #ffffff; */
  color: var(--dark-text);
  /* background: #283142; */
  background: var(--table-action-button-background);
  height: 22px;
  min-width: 22px;
  margin: 0 0 0 10px;
  font-size: 12px;
  line-height: 17px;
  font-family: "Poppins-Regular";
}

.footer-sec .Mui-selected,
.footer-sec .MuiPaginationItem-rounded:hover {
  /* background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0%
    0%; */
  background: var(--gradient-button);
  color: var(--light-text);
}

.pagination-sec .MuiPaginationItem-icon {
  width: 16px;
  height: 16px;
}

.MuiDataGrid-footerContainer {
  /* display: none !important; */
}

.tracking-chart-sub-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.tracking-chart-sub-header .trade-btn-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tracking-sub-btn-main .primary-btn-main .header-btn {
  padding: 6px;
}

.tracking-chart-sub-header .trade-btn-flex .trade-btn {
  width: 60px;
  height: 32px;
  margin-left: 10px;
  text-align: center;
  align-content: center;
  border-radius: 5px;
  font-size: 12px;
  line-height: 17px;
  font-family: "Poppins-Regular";
  color: #ffffff;
}

.tracking-window-main
  .table-sec
  .css-12u49z-MuiDataGrid-root
  .MuiDataGrid-columnHeader:nth-last-child(2):after {
  display: none;
}

.tracking-window-main .css-8atqhb {
  height: calc(100vh - 140px);
}

.exit-btn-bg {
  min-width: 90px;
  height: 20px;
  background: #916aff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 15px;
  font-family: "Poppins-Regular";
  color: #ffffff;
  border-radius: 12px;
  position: relative;
  margin: 0 8px 0 0;
  display: none;
}

.exit-btn-bg .exit-ic {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
}

.profile-content-sec {
  padding: 0;
  /* background-image: url(../images/jpg/profile-bg.png) !important; */
  /* background-image: linear-gradient(rgb(152, 209, 154 /0.) ), url(../images/jpg/profile-bg.jpg) !important; */
  min-height: calc(100vh - 100px);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;

  /* flex-direction: column; */
}

.profile-wrapper {
  width: 830px;
  text-align: center;
}

.profile-wrapper .profile-title {
  font-size: 36px;
  line-height: 51px;
  font-family: "Poppins-Medium";
  /* color: #ffffff; */
  color: var(--dark-text);
}

.profile-sec {
  /* margin: 60px 0 50px 0; */
  flex-direction: column;
}

.prof-img {
  border-radius: 50%;
  border: 1px solid var(--modal-button-border);
  position: relative;
  width: 150px;
  height: 150px;
}

.prof-img .profile-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profile-input-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.profile-input-wrapper .cus-input-grp {
  width: 400px;
  margin: 0 30px 40px 0;
}

.profile-input-wrapper .cus-input-grp .cus-lable {
  font-size: 18px;
  line-height: 25px;
  font-family: "Poppins-Regular";
  /* color: #ffffff; */
  color: var(--dark-text);
  text-align: left;
  margin: 0 0 15px 0;
}

.profile-input-wrapper .cus-input-grp:nth-child(2n + 2) {
  margin: 0 0px 40px 0;
}

.primary-btn-main .save-changes-btn {
  width: 200px;
  margin: 20px 0 30px 0;
}

.primary-btn-main .tracking-window {
  width: 200px;
  margin: 20px 0 0px 0;
}

.profile-edit {
  min-width: 25px !important;
  height: 25px;
  border-radius: 50% !important;
  position: absolute !important;
  bottom: 13px;
  right: 7px;
  border: none !important;
  display: flex !important;
  z-index: 9;
}

.gradient-bg {
  /* background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0% 0%; */
  background: var(--profile-edit-bg) !important;
}

.toggle-menu-sec {
  position: absolute;
  top: 65px;
  right: 0;
  width: 281px;
  display: none;
}

.bar-btn .toggle-menu-sec {
  display: block;
}

.toggle-menu-sec .main-toggle-list {
  /* background: #283142; */
  background: var(--menu-box-background);

  color: #fff;
  padding: 20px;
  border-radius: 6px;
}

.toggle-list-btn .toggle-list-ic {
  margin-right: 15px;
}

.toggle-menu-sec .toggle-list-btn {
  padding: 12px 20px !important;
  /* background: #1d2330; */
  background: var(--menu-box-buttons);
  border-radius: 6px;
  margin-bottom: 15px;
  height: 48px;
  position: relative;
  /* color: var(--dark-text); */
}

/* .toggle-menu-sec .toggle-list-btn:hover,
.toggle-menu-sec .toggle-list-btn.active{
    background: transparent linear-gradient(180deg, #5863E5 0%, #8789FB 100%) 0% 0%;
} */
.toggle-menu-sec .toggle-list-btn:hover,
.toggle-menu-sec .toggle-list-btn.active {
  background: var(--menu-box-buttons-hover);
  color: #ffffff;
}

.list-listChecked,
.toggle-menu-sec .toggle-list-btn.active .list-check-box {
  display: none;
}

.toggle-menu-sec .toggle-list-btn.active .list-listChecked {
  display: block;
}

.coll-main {
  margin: 0 0 0 22px;
}

.main-toggle-list > .toggle-list-btn:last-child {
  margin-bottom: 0;
}

.toggle-menu-sec .toggle-list-btn .list-check-box {
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.dashboard-main .buy-sell-watch-main,
.buy-sell-watch-main {
  display: none;
}

.buy-sell-watch-main.show-count {
  display: inline-flex;
}

.drawer-btn {
  top: 50%;
  transform: translateY(-50%);
}

.drawer-btn {
  position: absolute;
  background-image: url(../images/svg/drawer-ic.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 16.48px;
  height: 54px;
  top: 50%;
  transform: translateY(-50%);
  left: -15.6px;
  justify-content: center;
  display: none;
}

.drawer-btn .drop-left-ic {
  width: 6.59px;
  height: 11.28px;
}

/* .six-column-wrapper .chart_ohcl,
.nine-column-wrapper .chart_ohcl {
  display: none;
} */

.hide-chart-header {
  position: relative;
}

.chart-hover-sec {
  display: none;
}

/* .hide-chart-header:hover .chart-hover-sec {
  display: block;
} */

.hide-chart-header:hover .active-chart-hover {
  display: block;
}

.chart-hover-sec {
  /* display: flex;
    align-items: center;
    justify-content: center; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1d2330db;
  /* border-radius: 6px; */
  padding: 10px 10px 10px 20px;
}

/* .grid-small .hover-detail-sec .chart-header-left .chart-text {
  font-size: 12px;
  line-height: 18px;
} */

.grid-small .hover-detail-sec .chart-header-left .chart-title {
  font-size: 0.72vw;
  line-height: 20px;
}

.dots-btn-sec {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.watch-dot-btn-sec .dots-btn-sec .buy-btn {
  min-width: 50px;
  height: 16px;
  font-size: 0.42vw;
  line-height: 11px;
  font-family: "Poppins-Regular";
  /* margin: 0 8px; */
}

.chart-hover-sec .buy-min-btn-sec {
  margin: 0 8px;
}

.hover-detail-sec .chart-header-left .chart-text {
  font-size: 0.72vw;
  line-height: 1.04vw;
  margin-right: 8px;
  margin-bottom: 5px;
}

.hover-detail-sec .chart-header-time .chart-text {
  font-size: 0.72vw !important;
  line-height: 1.04vw;
  margin-right: 8px;
}

.hover-detail-sec .chart-header-left .red-down-aroow {
  width: 0.5vw;
  height: 0.4vw;
  margin-left: 6px;
}

.one-min-bg {
  width: 52px;
  height: 16px;
  border-radius: 4px;
  background: #42506f;
  font-size: 8px;
  line-height: 11px;
  color: #ffffff;
  font-family: "Poppins-Regular";
  margin: 6px 0 0 0;
}

.hover-detail-sec .hov-dow-btn {
  background: #283142;
  width: 20px;
  height: 20px;
  border-radius: 3px !important;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

/* .dots-btn-sec{
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
} */
.dots-btn-sec .white-dots-img {
  width: 3px;
  height: 10px;
  object-fit: cover;
  cursor: pointer;
}

/* .chart-hover-sec .round-chart{
min-width: 30px;
height: 30px;
} */
/* .hover-detail-sec{
    display: flex;
} */
/* .stock-content .chart-title{
font-size: 14px;
line-height: 20px;
color: #FFFFFF;
font-family: 'Poppins-Regular';
margin-bottom: 6px;
}
.hover-detail-sec .chart-text {
    font-size: 12px;
    line-height: 18px;
}
.hover-detail-sec .buy-btn{
border-radius: 4px;
margin: 12px 0 0 0;
font-size: 8px;
line-height: 11px;
min-width: 50px;
height: 16px;
}
.bg-green{
    background: #2EBE54 !important;
} */
.nine-column-wrapper .dash-grid .grid-main {
  padding: 0 5px 0px 5px !important;
  margin-bottom: 8px !important;
}

.nine-column-wrapper .dash-grid .chart-main {
  margin-left: -5px;
  margin-right: -5px;
}

/* .nine-column-wrapper .stock-name-sec .chart-text{
    margin-right: 0;
}
.nine-column-wrapper .chart-hover-sec{
    padding: 13px 8px;
} */
/* .nine-column-wrapper .chart-hover-sec .round-chart {
    min-width: 20px;
    height: 20px;
    margin-right: 6px;
}
.watch-down-btn{
    display: flex;
}
.nine-column-wrapper .hover-detail-sec .buy-btn{
margin: 8px 8px 0 0;
} */
.sorting-body-wrapper {
  padding: 30px 20px;
  position: relative;
}

.filter-btn-main .filter-btn:hover {
  background-color: var(--modal-sorting-button) !important;
}

.select-dark-theme .filter-btn-main .reload-img,
.select-dark-theme .name-wrap .sort-icon,
.select-dark-theme .name-wrap .arrow-round-img {
  filter: invert(100%);
}

.filter-btn-main .filter-btn {
  min-width: 24px;
  height: 24px;
  /* background: #42506f; */

  background: var(--gradient-button);
  border-radius: 4px;
  position: absolute;
  top: 20px;
  right: 15px;
  position: absolute;
  right: 20px;
  top: 63px;
}

.filter-btn-main .reset-btn-bg,
.filter-btn-main .reset-btn-bg:hover {
  background: var(--modal-sorting-button);
 }

.filter-btn-main .reset-btn-bg.active,
.filter-btn-main .reset-btn-bg.active:hover {
  background: var(--gradient-button);
}

.name-wrap .cus-lablel {
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Regular";
  margin-bottom: 10px;
}

.name-wrap .btn-bg {
  width: 40px;
  height: 40px;
}

.btn-bg.active {
  /* background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0% 0% !important; */
  background: var(--gradient-button);
}

.btn-bg.order-sort-bg {
  /* background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0% 0% !important; */
  background: var(--modal-sorting-button);
}

.select-light-theme .name-wrap .order-btn-bg .sort-icon {
  filter: invert(100%);
}

.select-dark-theme .name-wrap .order-btn-bg .sort-icon {
  filter: invert(0%);
}

.name-wrap {
  margin-bottom: 20px;
}

.name-warp-tracking-window {
  margin-bottom: 0px;
}

.sorting-tag-sec {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: space-between;
}

.sorting-tag-sec.flex-reverse {
  flex-direction: row-reverse;
}

.sorting-label-flex {
  display: flex;
  align-items: center;
  column-gap: 30px;
  margin-bottom: 10px;
}

.sorting-label-flex .cus-lablel {
  margin-bottom: 0;
}

.sorting-label-flex .sorting-restrict-msg {
    font-family: "Poppins-Regular";
    color: #ff5252;
    font-size: 14px;
    line-height: 20px;
    display: block;
 }

.tag-main {
  width: 112px;
  height: 40px;
  /* background: #283142; */
  background: var(--modal-sorting-button);
  /* border: 1px solid var(--sorting-button-border); */
  border-radius: 4px;
  padding: 9px 12px;
  display: flex;
  margin: 0 12px 0 0;
  cursor: default;
}

.tag-main:last-child {
  margin: 0;
}

.name-wrap .tag-main .btn-bg {
  width: 22px !important;
  height: 22px;
  font-size: 12px;
  line-height: 17px;
  font-family: "Poppins-Regular";
  /* background: #42506f; */
  background: var(--modal-sorting-inner-text-bg);
  margin-right: 12px;
  /* color: var(--common-text); */
  color: #fff;
}

.sorting-tag-sec .tag-main .btn-bg {
  background: var(--gradient-button);
}

.select-dark-theme .sorting-tag-sec .tag-main .btn-bg {
  color: #000000;
}

.sorting-tag-sec .sorting-tag-active .btn-bg {
  background: var(--modal-sorting-inner-text-bg);
  color: #ffffff;
}

.select-dark-theme .sorting-tag-sec .sorting-tag-active .btn-bg {
  color: #ffffff;
}

.tag-main .tag-label {
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  /* color: #ffffff; */
  color: var(--dark-text);
}

.sorting-time-frame .tag-main {
  width: 120px;
  padding: 10px 27px;
  margin: 0;
}

.arrow-round-sec {
  width: 40px;
  height: 40px;
  /* background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0% 0% !important; */
  background: var(--modal-sorting-button);
  border-radius: 40px;
  margin-right: 15px;
  cursor: pointer;
}

.sorting-time-frame .arrow-round-sec {
  margin-right: 0;
}

.arrow-round-sec.active {
  background: var(--gradient-button);
 }

/* .round-border-sec{
    width: 18px;
    height: 18px;
border-radius: 50%;
    border: 1px solid #FFFFFF;
 } */
.left-arrow-img {
  display: none;
}

.grid-setting-wrapper {
  padding: 30px 30px 15px 30px;
  border-top: 1px solid var(--modal-border);
}

.grid-box {
  width: 30px;
  height: 30px;
  border: 1px solid var(--grid-modal--button-border);
  /* margin: 0 9px 10px 0; */
}

.grid-box.active {
  background: var(--grid-modal-active-button-background) 0% 0% no-repeat
    padding-box;
  border: 1px solid var(--grid-modal--button-border);
}

.grid-sec {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 15px;
  row-gap: 6px;
  padding-bottom: 10px;
  /* border-bottom: 1px solid var(--text-field-border);*/
}

.grid-sec .grid-box.active {
  background-color: var(--grid-active);
  /* border-color: #44a147; */
}

.grid-sec .grid-box:nth-child(10n + 10) {
  margin: 0 0 10px 0;
}

.grid-size {
  position: relative;
}

.grid-size .four-text {
  margin: 15px 0 0 0;
  text-align: center;
  font-size: 12px;
  line-height: 17px;
  font-family: "Poppins-Light";
}

.setting-white-img {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.gap-thickness-color-sec .gap-sec {
  width: 166.66px;
}

.gap-thickness-color-sec {
  display: flex;
}

.gap-sec .cus-input-grp .cus-lable {
  font-size: 18px;
  line-height: 25px;
  font-family: "Poppins-Medium";
}

.gap-sec .cus-input-grp .grid-line-color-heading {
  margin-bottom: 10px;
}

/* .gap-sec .cus-input-grp .form-group .form-control{
    background: #1D2330;
  } */
.gap-sec .form-group .form-control input {
  /* background: #1d2330; */
  background: var(--textfield-background);
}

.line-thickness {
  margin: 0 20.3px 0 19.3px;
}

.grid-modal-main {
  padding: 30px;
}

.grid-modal-main .cus-input-grp {
  margin-top: 0;
}

.check-color-pick-sec .check-color-box {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

.check-color-pick-sec {
  display: flex;
}

.check-color-pick-sec .color-label {
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Regular";
}

.check-color-pick-sec .previous-color-main {
  margin-right: 20px;
}

.check-color-pick-sec .previous-color-btn {
  padding: 6px 8px;
  min-width: 24px;
  height: 24px;
  margin-top: 4px;
}

.check-color-pick-sec .color-input input[type="color"] {
  width: 50px;
  height: 50px;
  margin: 0 8.5px;
  /* border: 1px solid var(--modal-button-border); */
}

.eyeDrop-sec {
  /* border: 1px solid #42506f; */
  /* background: #1d2330; */
  border: 1px solid var(--modal-button-border);
  /* background: var(); */
}

.primary-btn-main .chart-repot-btn {
  width: 110px;
  height: 40px;
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Regular";
  /* color: #fff; */
  color: var(--light-text);
  background: var(--order-report-button-active-background) !important;
}

.select-light-theme .primary-btn-main .repot-btn-cus {
  border-radius: 0 6px 6px 0;
  background: var(--gradient-button);
  color: #fff;
}

.report-chart-btn-sec-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 7px; */
  display: none;
}

.primary-btn-main .chart-btn-cus {
  border-radius: 0 6px 6px 0;
  background: var(--order-report-button-background) !important;
  color: #fff;
}

.primary-btn-main .repot-btn-text-active {
  color: black !important;
}

.primary-btn-main .chart-btn-cus {
  color: var(--dark-text);
}

.report-chart-btn-sec {
  display: flex;
  margin-left: 15px;
  border-radius: 6px;
}

.back-btn-main {
  width: 25px;
  height: 25px;
}

.report-back-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.performance-report-wrap .report-chart-btn-sec-main {
  display: inline-flex;
}

.performance-report-wrap .grid-list-btn-sec,
.performance-report-wrap .script-btn {
  display: none;
}

/* .open-order-report .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-last-child(2),
.user-management-table .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-last-child(2),
.index-management-table .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-last-child(2) {
  justify-content: flex-start !important;
} */

.user-list-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 15px;
}

.user-add-flex {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.select-light-theme .user-add-flex .back-btn-main .report-back-img,
.select-light-theme .index-management-add-flex .back-btn-main .report-back-img { 
  filter: invert(100%);
}

.user-list-flex .cus-input-grp {
  margin-top: 0;
}

.user-list-flex .form-group .form-control input {
  padding: 8.5px 14px;
}

.bg-gray {
  background: #1d2330 !important;
  /* background: var(--menu-bar-button) !important; */
}

.tab-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-content-main .css-1aquho2-MuiTabs-indicator {
  display: none;
}

.report-tab-panel .css-19kzrtu {
  padding: 0;
}

.performance-report-wrap .tab-content-main .tab-body {
  margin-top: 20px;
}

.report-tab-content-main .tabs-content-title {
  /* background: #1d2330; */
  background: var(--order-report-button-background) !important;
  width: 200px;
  height: 50px;
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Regular";
}

.recentindex-tab-main .equity-tab {
  width: 150px;
}

.recentindex-tab-main .future-option-tab {
  width: 227px;
}

.recentindex-tab-main .commodities-tab {
  width: 177px;
}

.report-tab-content-main .tabs-content-title:last-child {
  border-radius: 0 6px 6px 0;
}

.report-tab-content-main .tabs-content-title:first-child {
  border-radius: 6px 0 0 6px;
}

.report-tab-content-main .tabs-content-title.Mui-selected {
  /* background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0%
    0%; */
  background: var(--gradient-button);
  /* color: #ffffff; */
  color: var(--light-text);
  border-radius: 6px;
}

.editRepot-download-sec {
  display: flex;
  justify-content: flex-start;
}

.primary-btn-main .edit-download-btn {
  width: 156px;
  height: 50px;
  font-size: 18px;
  line-height: 25px;
  font-family: "Poppins-Regular";
  margin-left: 20px;
}

.green-bg {
  /* background: #2ebe54 !important; */
  /* background: var(--Download-button-background) !important; */
  /* border: 1px solid var(--Download-button-border) !important; */
  /* color: var(--dark-text) !important; */
}

/* .performance-tab .MuiDataGrid-cell:last-child{ */
/* .performance-tab .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-last-child(2) { */
/* justify-content: flex-start !important; */
/* } */

/* .report-tab-content-main .MuiDataGrid-virtualScroller {
    overflow-x: hidden;
} */

/* width */
.MuiDataGrid-virtualScroller::-webkit-scrollbar,
.open-order-body::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.MuiDataGrid-virtualScroller::-webkit-scrollbar-track,
.open-order-body::-webkit-scrollbar-track {
  /* background: #1d2330; */
  background: var(--scroll-bg) !important;
}

/* Handle */
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb,
.open-order-body::-webkit-scrollbar-thumb {
  /* background: #526ba2; */
  background: var(--scroll-thumb) !important;
  border-radius: 5px;
}

/* Handle on hover */
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover,
.open-order-body::-webkit-scrollbar-thumb:hover {
  /* background: #526ba2; */
  background: var(--scroll-hover) !important;
}

.slectTwo-wrap .select2-container--default .select2-selection--multiple {
  background-color: #1d2330;
  border: 1px solid #42506f;
  width: 291px;
  height: 50px;
}

.slectTwo-wrap
  .select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: 1px solid var(--text-field-focus);
}

/* 
.slectTwo-wrap{
    width: 291px;
    height: 50px;

} */
.slectTwo-wrap
  .select2-container--default
  .select2-search--inline
  .select2-search__field,
.slectTwo-wrap .select2-container--default .select2-selection--single {
  width: 291px !important;
  height: 50px;
  margin: 0px;
  padding: 12.5px 20px;
  /* color: #5a76b4; */
  color: var(--dark-text);
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
  /* background: #1d2330; */
  background: var(--textfield-background);
  border: 1px solid var(--text-field-border);
}

.select2-dropdown {
  z-index: 9999 !important;
  border-color: var(--modal-button-border) !important;
  border-radius: 8px !important;
  /* background-color: #42506f !important; */
  background: var(--chart-modal-dropdown-background) !important;
  padding: 18px;
}

.slectTwo-wrap
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 0;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered,
.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: var(--text-color) !important;
  line-height: 23px !important;
  font-size: 18px;
  padding: 0 !important;
}

.select2-container--default .select2-results > .select2-results__options {
  background: #42506f;
  border-color: #51638b;
  /* border-radius: 6px; */
  /* padding: 18px; */
}

.select2-selection__arrow {
  display: none;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
  font-size: 14px;
  line-height: 20px;
  color: white;
  font-family: "Poppins-Regular";
  padding: 9px 20px !important;
  /* border-bottom: 1px solid var(--grid-modal--button-border) !important; */
  outline: none;
}

.select2-results__option:last-child {
  border-bottom: none;
}

.select2-container--open .select2-dropdown {
  top: 10px;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  /* background: #1d2330 !important; */
  background: var(--menu-box-buttons) !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  /* background: #1d2330; */
  background: var(--textfield-background) !important;
  border: none !important;
  border-radius: 6px;
  height: 40px;
  outline: none;
  /* color: #5a76b4; */
  color: var(--text-color);
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  padding: 10px 20px;
}

.select2-search--dropdown {
  padding: 4px 0 !important;
}

.slectTwo-wrap
  .select2-container--default
  .select2-search--inline
  .select2-search__field:focus,
.slectTwo-wrap .select2-container--default .select2-selection--single:focus {
  outline: none;
}

.select2-container--open .select2-dropdown--above {
  width: 291px;
  top: -10px;
}

.report-modal .report-buy-sell-sec {
  display: flex;
  margin-left: 32px;
}

.report-buy-sell-sec .report-buy-sell-btn {
  width: 100px;
  height: 50px;
  border: 1px solid var(--modal-button-border);
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Regular";
  border-radius: 6px;
  margin-right: 20px;
}

.report-buy-sell-sec .report-buy-sell-btn.active {
  /* background: #3a6dd6; */
  background: var(--download-modal-intraday-active-button-svg);
  /* border-color: #3a6dd6; */
  border-color: transparent;
  color: var(--common-text);
}

.report-buy-sell-sec .report-buy-sell-btn.active-buy {
  background: #008000;
}

.report-buy-sell-sec .report-buy-sell-btn.active-sell {
  background: #ff0000;
}

.report-modal-sec .time-frame-ul .time-frame-li {
  max-width: 90px;
  justify-content: center;
  padding: 6.5px 9px;
}

.report-modal-sec .time-frame-left {
  width: 100%;
}

.report-category-wrapper .modal-ul-li .modal-ul {
  margin: 0;
}

.report-category-select-box {
  width: 100%;
  margin-bottom: 20px;
}

.report-buy-sell-main {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}

.report-buy-sell-main .report-buy-sell-sec {
  margin-left: 0;
}

.search-buy-sell,
.report-intra-pos-sec {
  display: flex;
}

.report-intra-pos-sec {
  margin: 20px 0;
}

.report-intra-pos-sec .report-intra-pos-btn {
  width: 170px;
  height: 50px;
  border: 1px solid var(--modal-button-border);
  font-size: 18px;
  line-height: 25px;
  font-family: "Poppins-Regular";
  border-radius: 6px;
  margin-right: 20px;
  padding: 12.5px 12px;
  display: flex;
}

.report-intra-pos-sec .report-intra-pos-btn.active #Rectangle_183 {
  /* fill: #3a6dd6; */
  fill: var(--download-modal-intraday-active-button-svg);
}

.report-intra-pos-sec .report-intra-pos-btn #Rectangle_183 {
  /* fill: #3a6dd6; */
  fill: var(--download-modal-intraday-button-svg);
}

.darck-check {
  margin-right: 15px;
}

.download-chart-round {
  border: 1px;
  border-radius: 17px;
}

.report-intra-pos-main .time-frame-left {
  width: 100%;
}

.report-intra-pos-main .intraday-sec .time-frame-ul .time-frame-li,
.report-intra-pos-main .report-modal-sec .time-frame-ul .time-frame-li {
  width: 105px;
  height: 30px;
  margin-right: 10px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

.view-cleare-sec {
  margin-left: 0;
}

.view-cleare-sec {
  margin-left: 0;
}

.view-cleare-sec .report-buy-sell-btn {
  width: 123px;
  height: 37px;
  font-size: 14px;
  line-height: 25px;
  cursor: pointer;
}

.style-sec .modal-ul-li {
  margin-top: 8px;
}

.download-chart-wrapper {
  padding: 30px 25px;
  flex-direction: column;
}

.download-chart-wrapper .time-frame-sec {
  margin-bottom: 0;
}

.download-chart-wrapper .modal-chart-img {
  height: auto;
  width: auto;
  object-fit: cover;
}

.download-chart-wrapper .style-sec .modal-ul-li .modal-ul .modal-li {
  width: 180px;
  height: 50px;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 13px 10px 13px 12px;
  font-size: 12px;
  line-height: 17px;
  justify-content: space-between !important;
}

.download-chart-wrapper
  .style-sec
  .modal-ul-li
  .modal-ul
  .modal-li:nth-child(3n + 3) {
  margin-right: 0;
}

.download-chart-wrapper .primary-btn-main .edit-download-btn {
  margin: 30px 0 0 0;
  width: 215px;
  height: 50px;
}

.download-chart-date {
  width: 53%;
}

.report-date-range-box .form-control input {
  text-align: center;
}

.date-range-sec {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.datepicker-wrap .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  /* border-color:1px solid red !important; */
  border: var(--modal-button-border) !important;
  border-radius: 6px;
}

.datepicker-wrap .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  /* color: #fff; */
  color: var(--dark-text) !important;
  font-size: 18px;
  line-height: 25px;
  font-family: "Poppins-Regular";
  padding: 12px 25px;
}

.datepicker-wrap
  .MuiInputBase-formControl:hover
  .MuiOutlinedInput-notchedOutline {
  /* border: 1px solid #42506f !important; */
  border: 1px solid var(--modal-button-border) !important;
}

.datepicker-wrap .MuiInputBase-formControl .MuiOutlinedInput-notchedOutline {
  /* border: 1px solid #42506f !important; */
  border: 1px solid var(--modal-button-border) !important;
}

.chart-date-picker {
  width: 200px;
}

.date-range-sec .to-text {
  margin: 0 20px;
  font-size: 18px;
  line-height: 25px;
  font-family: "Poppins-Regular";
}

.datepicker-wrap
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input::placeholder {
  color: #fff;
}

.view-cleare-sec {
  margin-top: 30px;
}

.two-column-wrapper .small-chart-box .chat-header-right .chart-time-sec {
  display: block;
}

/* .two-column-wrapper .chat-header-right .min-btn{
display: none;
} */
.two-column-wrapper .small-chart-box .buy-min-btn-sec:hover .min-btn {
  display: none !important;
}

.chart-header-main .chat-header-right .min-btn.green-btn,
.chart-hover-sec .min-btn.green-btn {
  background-color: #008000;
}

.chart-header-main .chat-header-right .min-btn.red-btn,
.chart-hover-sec .min-btn.red-btn {
  background-color: #ff0000;
}

.chart-header-main .chat-header-right .min-btn.blue-btn,
.chart-hover-sec .min-btn.blue-btn {
  background-color: #3a6dd6;
}

.two-column-wrapper .small-chart-box .buy-min-btn-sec:hover .buy-btn {
  display: inline-flex;
}

.add-btn-sec .replace-btn {
  /* background: #42506f !important; */
  background: var(--modal-replace-button-background) !important;
  border: 1px solid var(--modal-button-border);
  color: var(--dark-text);
}

.gradient-bg {
  background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0%
    0%;
}

.index-input-grp .check-btn {
  right: 0;
  left: initial;
}

.open-order-list-wrap .buy-sell-watch-main,
.intraday-tab-wrapper .buy-sell-watch-main {
  display: inline-flex;
}

.open-order-list-wrap .script-btn {
  display: none;
}

.hide-markets-btn {
  display: none;
}

.round-btn-main .round-btn:hover {
  background: #42506f;
}

.full-sidebar-top {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  height: 100px;
  display: flex;
  align-items: center;
  width: 80px;
  border-left: 2px solid var(--sidebar-border);
}

.full-sidebar-top .main-ul {
  padding: 0 14.31px 0 15.5px;
}

.full-sidebar-top .main-ul .main-li {
  padding: 0;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  height: 3px;
}

/* .grid-img-sec {
  margin-left: 15px;
} */

.analysis-window-btn.active svg path,
.grid-img-sec.active svg path {
  fill: #fff;
}

.opacity-25 {
  opacity: 25%;
}

.opacity-50 {
  opacity: 50%;
}

.opacity-75 {
  opacity: 75%;
}

.bar-img {
  width: 17px;
  height: 17px;
  object-fit: contain;
}

.bar-img:hover {
  width: 20px;
  height: 20px;
}

.d-flex {
  display: flex;
}

.hover-detail-sec .chart-header-left {
  align-items: flex-start;
  flex-direction: column;
}

/* .chart-header-left .chart-title{
    margin-bottom: 6px !important;
} */
.hover-detail-sec .one-min-bg {
  min-width: 64px;
  width: max-content;
  height: 1.1vw;
  font-size: 0.66vw;
  line-height: 15px;
  margin: 12px 0 0 0;
  padding: 0.3vw 0.3vw;
}

.thickness-drop .MuiSelect-nativeInput {
  height: 20px;
}

.css-12u49z-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  padding: 0 20px;
}

.sorting-body-wrapper .filter-btn-main .filter-btn {
  width: 40px;
  height: 40px;
}

.sorting-body-wrapper .filter-btn-main .reload-img {
  width: 16px;
  height: 16px;
}

.filter-btn-main .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  /* background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0% 0%; */
  background: var(--gradient-button);
}

.toggle-menu-sec .toggle-menu-text .MuiListItemText-primary {
  font-size: 18px;
  line-height: 25px;
  font-family: "Poppins-Regular" !important;
}

.overlay-main {
  position: fixed;
  right: 0;
  max-width: 100%;
  background: rgb(0 0 0 / 50%);
  top: 0;
  bottom: 0;
  width: 100%;
  transition: all 0.3s;
  z-index: 9999;
}

.over-d-none {
  display: none;
}

.table-sec .MuiDataGrid-cellContent {
  font-size: 18px !important;
  line-height: 25px;
  font-family: "Poppins-Regular";
}

/* width */
.sidebar-main::-webkit-scrollbar {
  width: 5px;
  display: none;
}

/* Track */
.sidebar-main::-webkit-scrollbar-track {
  /* background: #1d2330; */
  background: var(--scroll-bg);
}

/* Handle */
.sidebar-main::-webkit-scrollbar-thumb {
  /* background: #526ba2; */
  background: var(--scroll-thumb);
  border-radius: 5px;
}

/* Handle on hover */
.sidebar-main::-webkit-scrollbar-thumb:hover {
  /* background: #526ba2; */
  background: var(--scroll-hover);
}

.result_hover {
  padding-left: 345px;
  z-index: 998;
}

.sidebar-left.result_hover {
  padding-left: 0 !important;
  padding-right: 345px !important;
}

.sidebar-left .menu-name {
  left: 44px;
  z-index: 999;
  border-radius: 0px 6px 6px 0px;
}

.sidebar-left .li-hover {
  left: 44px;
  z-index: 999;
}

.sidebar-left .li-hover::before {
  left: -17px;
}

.li-hover:before,
.menu-name:before {
  content: " ";
  width: 17px;
  height: 50px;
  background: transparent;
  position: absolute;
  right: -17px;
  top: 0;
}

/* Auth pages css Rumit start */
.auth-error-msg {
  font-family: "Poppins-Regular";
  color: #ff5252;
  line-height: 25px;
  display: block;
}

/* Auth pages css Rumit end */
.cus-input-grp span.auth-error-msg {
  min-height: 25px;
}

.login-inner-main .mt-5 {
  margin-top: 5px;
}

.input-box {
  /* border: 1px solid #42506f; */

  border: 1px solid var(--modal-button-border);
  padding: 7.5px 8.5px 7.5px 54px;
  /* color: #ffffff; */
  color: var(--dark-text);
  font-size: 16px;
  line-height: 23px;
  border-radius: 6px;
  font-family: "Poppins-Light";
}

.cus-autocom .MuiInputBase-fullWidth {
  padding: 0 !important;
}

.cus-autocom .MuiInputBase-input {
  /* border: 1px solid #42506f; */
  border: 1px solid var(--text-field-border);
  border-radius: 6px;
  /* color: #fff; */
  color: var(--dark-text);
  font-family: "Poppins-Light";
  font-size: 16px;
  line-height: 23px;
  padding: 7.5px 20px !important;
  outline: none;
}

.cus-autocom .MuiAutocomplete-endAdornment svg path {
  fill: #ffffff;
}

.cus-autocom .MuiAutocomplete-popupIndicator {
  display: none;
}

.cus-autocom .MuiAutocomplete-endAdornment {
  right: 35px !important;
}

/* Chrome, Safari, Edge, Opera */
.profile-input-wrapper input::-webkit-outer-spin-button,
.profile-input-wrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--textfield-placeholder) !important;
}

.chart_ohcl.chart_date {
  position: absolute;
  bottom: 2%;
  top: auto;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover {
  border-color: transparent !important;
}

.border-radius-graph-bottom {
  border-radius: 0 0 5px 5px;
  /* overflow: hidden; */
}

.loaded-chart-none {
  display: none;
}

.loaded-chart {
  display: block;
}

.border-radius-graph-all {
  border-radius: 5px 5px 5px 5px;
  /* overflow: hidden; */
}

.table-sec .MuiDataGrid-overlay {
  background: var(--table-background) !important;
}

/* medium theme classes */
.select-medium-theme .MuiMenu-paper ul {
  background: #181d28 !important;
  color: #ffffff !important;
  border: 1px solid #42506f !important;
}

.select-medium-theme .select2-dropdown ul {
  background: #181d28 !important;
}

.select-medium-theme .select2-results__option {
  border-bottom: 1px solid #51638b !important;
  background-color: #42506f !important;
}

.select-medium-theme
  .select2-container--default
  .select2-results__option[aria-selected="true"] {
  background-color: #ddd !important;
  color: #000000 !important;
}

.select-medium-theme
  .select2-container--default
  .select2-results
  > .select2-results__options {
  border-color: #5a76b4 !important;
}

.select-medium-theme .select2-container--open .select2-dropdown--below {
  background: #42506f !important;
  border: 1px solid #5a76b4 !important;
}

.select-medium-theme
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field {
  background: #1d2330 !important;
  color: #5a76b4 !important;
}

.select-medium-theme .MuiPaper-elevation ul,
.MuiAutocomplete-noOptions {
  background: #181d28 !important;
  color: #ffffff !important;
  border: 1px solid #5a76b4 !important;
}

.select-medium-theme .modal-header-sec .chart-header-main {
  background-color: #1e2431 !important;
}

.select-medium-theme .round-btn-main .download-btn {
  background: #1d2330 !important;
  border: 1px solid #283142 !important;
}

.select-medium-theme .chart-background {
  background-color: #000000 !important;
}

.select-medium-theme .head-menu {
  background-color: #141922;
}

.select-medium-theme .head-menu ul li {
  background-color: #1d2330 !important;
  color: #fff !important;
  cursor: pointer;
}

.select-medium-theme .head-menu ul .li-active {
  background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) !important;
  color: #fff !important;
}

.select-medium-theme .head-menu ul li.active,
.select-medium-theme .head-menu ul li:hover {
  background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) !important;
  color: #fff !important;
}

.select-medium-theme .chart-header-left .chart-title {
  color: #fff !important;
  margin-bottom: 5px;
}

.select-medium-theme .chart-header-left .value-text {
  color: #fff !important;
}

.select-medium-theme .login-bg {
  background-image: url("../images//jpg/main-dark-bg.jpg") !important;
}

.select-medium-theme .profile-content-sec {
  background-image: url(../images//jpg/profile-dark-bg.jpg) !important;
}

.select-medium-theme
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  /* background: #1d2330 !important; */
  background-color: #1d2330 !important;
  color: #fff !important;
}

.select-medium-theme .select2-dropdown {
  background: #42506f !important;
  border: 1px solid #5a76b4 !important;
}

/* light theme classes */
.select-light-theme .MuiMenu-paper ul {
  background: #b6e5b8 !important;
  color: #000000 !important;
}

.select-light-theme .select2-dropdown ul {
  background: #98d19a !important;
}

.select-light-theme .select2-results__option {
  border-bottom: 1px solid #b6e5b8 !important;
  background-color: #98d19a !important;
  color: #000000 !important;
}

.select-light-theme
  .select2-container--default
  .select2-results__option[aria-selected="true"] {
  background-color: #44a147 !important;
  color: #fff !important;
}

.select-light-theme
  .select2-container--default
  .select2-results
  > .select2-results__options {
  border-color: #b6e5b8 !important;
}

.select-light-theme .select2-container--open .select2-dropdown--below {
  background: #98d19a !important;
  border: 1px solid #44a147 !important;
}

.select-light-theme
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field {
  background: #dbffde !important;
  color: #000000 !important;
}

.select-light-theme .MuiPaper-elevation ul,
.MuiAutocomplete-noOptions {
  background: #e3f2e3 !important;
  color: #000000 !important;
  border: 1px solid #009856 !important;
}

.select-light-theme .modal-header-sec .chart-header-main {
  background-color: #b6e5b8 !important;
}

.select-light-theme .round-btn-main .download-btn {
  background: #ffffff19 !important;
  border: 1px solid #ffffff19 !important;
}

.select-light-theme .chart-background {
  background-color: #ffffff !important;
}

.select-light-theme .head-menu {
  background: transparent linear-gradient(90deg, #287c85 0%, #44a147 100%) 0% 0%
    no-repeat padding-box;
}

.select-light-theme .head-menu ul li {
  background-color: #b6e5b8 !important;
  color: #000000;
}

.select-light-theme .head-menu ul .li-active {
  background: #009856 !important;
  color: #fff !important;
}

.select-light-theme .head-menu ul li.active,
.select-light-theme .head-menu ul li:hover {
  background: #009856 !important;
  color: #fff !important;
  cursor: pointer;
}

/* .select-light-theme .chart-header-left .chart-title {
  color: #000000 !important;
} */

.select-light-theme .chart-header-left .value-text {
  color: #000000 !important;
}

.select-light-theme .login-bg {
  background-image: linear-gradient(
      rgba(227, 242, 227, 0.9),
      rgba(227, 242, 227, 0.9)
    ),
    url(../images//jpg/main-bg.png) !important;
}

.select-light-theme .profile-content-sec {
  background-image: linear-gradient(
      rgba(152, 209, 154, 0.9),
      rgba(152, 209, 154, 0.9)
    ),
    url(../images//jpg/profile-bg.png) !important;
}

.select-light-theme
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  /* background: #1d2330 !important; */
  background-color: #009856 !important;
  color: #fff !important;
}

.select-light-theme .select2-dropdown {
  background-color: #98d19a !important;
  border: 1px solid #44a147 !important;
}

/* dark */

.select-dark-theme .MuiMenu-paper ul {
  background: #181d28 !important;
  color: #ffffff !important;
  border: 1px solid #42506f !important;
}

.select-dark-theme .select2-dropdown ul {
  background: #131111 !important;
}

.select-dark-theme .select2-results__option {
  border-bottom: 1px solid #8a8a8a !important;
  background-color: #232528 !important;
}

.select-dark-theme
  .select2-container--default
  .select2-results__option[aria-selected="true"] {
  background-color: #ffffff19 !important;
  color: #fff !important;
}

.select-dark-theme
  .select2-container--default
  .select2-results
  > .select2-results__options {
  border-color: #5a76b4 !important;
}

.select-dark-theme .select2-container--open .select2-dropdown--below {
  background: #131111 !important;
  border: 1px solid #191919 !important;
}

.select-dark-theme
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field {
  background: #000000 !important;
  color: #8a8a8a !important;
}

.select-dark-theme .MuiPaper-elevation ul,
.MuiAutocomplete-noOptions {
  background: #191919 !important;
  color: #ffffff !important;
  border: 1px solid #191919 !important;
}

.select-dark-theme .modal-header-sec .chart-header-main {
  background-color: #131111 !important;
}

.select-dark-theme .round-btn-main .download-btn {
  background: #1d1d1d !important;
  border: 1px solid #2e2e2e !important;
}

.select-dark-theme .chart-background {
  background-color: #000000 !important;
}

.select-dark-theme .head-menu {
  background-color: #000000 !important;
}

.select-dark-theme .head-menu ul li {
  background-color: #ffffff19 !important;
  color: #fff !important;
}

.select-dark-theme.head-menu ul .li-active {
  background: transparent linear-gradient(180deg, #a5ff7c 0%, #79ffd4 100%) !important;
  color: #000000 !important;
}

.select-dark-theme .head-menu ul li.active,
.select-dark-theme .head-menu ul li:hover {
  background: transparent linear-gradient(180deg, #a5ff7c 0%, #79ffd4 100%) !important;
  color: #000000 !important;
  cursor: pointer;
}

.select-dark-theme .chart-header-left .chart-title {
  color: #fff !important;
  margin-bottom: 5px;
}

.select-dark-theme .chart-header-left .value-text {
  color: #fff !important;
}

.select-dark-theme .login-bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../images//jpg/main-dark-bg.jpg) !important;
}

.select-dark-theme .profile-content-sec {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../images//jpg/profile-dark-bg.jpg) !important;
}

.select-dark-theme
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  /* background: #1d2330 !important; */
  background-color: #1d2330 !important;
  color: #fff !important;
}

.select-dark-theme .select2-dropdown {
  background: #131111 !important;
  border: 1px solid #191919 !important;
}

.hide-chart-header .chart-header-main,
.hide-chart-header .order-header-main {
  /* .hide-chart-header .chart_ohcl { */
  display: none;
}

.active-download-chart-header .chart-header-main,
.active-download-chart-header .chart_ohcl {
  display: block;
}

/* 
.otp-verification-input {
  margin-right: 20px;
  width: 60px !important;
  height: 60px !important;
  border-radius: 5px;
  border: 1px solid #c6c6c6;
  font-size: 20px;
  font-weight: 500;
}
.otp-verification-input-wrap {
  justify-content: center;
}
.update_key_shortcut{
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
} */

.form-group.update_key_shortcut .form-control .MuiOutlinedInput-root {
  border: 1px solid var(--text-field-border);
  margin-bottom: 10px;
  padding: 0 10px 0 0;
}

.form-group.update_key_shortcut .form-control input {
  border: 0;
}

.update_key_shortcut .MuiIconButton-root {
  color: #fff;
}

/* User mangement */
.main-content-sec .add-user {
  width: 130px;
  background: var(--gradient-button);
  color: white;
}

.select-dark-theme .main-content-sec .add-user {
  color: #000000;
}

.form-group .form-control-user {
  border: 1px solid var(--text-field-border);
  border-radius: 6px;
  width: 100%;

  /* background: #1d2330; */
  background: var(--textfield-background);
  /* color: #ffffff; */
  color: var(--dark-text);
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Light";
  height: 50px;
}

.form-group .form-control select::placeholder {
  color: #ffffff;
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Light";
  opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--modal-button-border) !important;
  border-width: 0px;
}

.modal-delete .delete-modal-inner-main {
  color: #fff;
  padding: 20px;
  border-radius: 6px;
  text-align: center;
}

.select-dark-theme .modal-delete .delete-modal-inner-main {
  background: #181d28 !important;
}

.select-medium-theme .modal-delete .delete-modal-inner-main {
  background: #42506f;
}

.select-light-theme .modal-delete .delete-modal-inner-main {
  background: #b6e5b8;
}

.modal-delete .delete-modal-inner-main .delete-modal-title {
  color: #358d69;
  font-size: 27px;
}

.modal-delete .delete-modal-inner-main .delete-modal-para {
  color: #358d69;
  margin-top: 17px;
}

.modal-delete .delete-modal-inner-main .modal-btn {
  background: transparent linear-gradient(90deg, #287c85 0%, #44a147 100%) 0% 0%
    no-repeat padding-box;
  color: white;

  width: 40%;
  height: 50px;
}

.modal-delete .delete-modal-inner-main .delete-modal-btn-flex {
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: space-around;
}

.modal-delete .delete-modal-inner-main .modal-cancel-btn {
  background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0%
    0% no-repeat padding-box;
}

.del-user-img-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.del-user-img-box .image-delete-user {
  width: 13%;
}

.form-group .search {
  width: 73%;
  height: 50px;
  position: relative;
}

.list-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25%;
}

.user-search-main {
  width: 60%;
}

.search-icon-box {
  position: absolute;
  top: 19.5%;
  right: 80%;
}

.form-group .custom_form_control {
  background-color: var(--textfield-background);
}

.form-group .custom_form_control input {
  border: none;
}

.form-group .form-control-header {
  background: var(--header-svg-background);
  border-radius: 4px;

  width: 130%;
}

.form-group .form-control-header .form-control {
  color: #ffffff;
}

.main-content-sec .user-btn-flex {
  display: flex;
  width: 20%;
  height: fit-content;
  justify-content: space-between;
  column-gap: 20px;
}

.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
  display: none !important;
}

.auto-box .user-autocomplete {
  background-color: transparent;
  width: 200px;
  padding: 0px;
  border: 1px solid var(--modal-button-border);
  border-radius: 4px;
}

.auto-box .user-autocomplete .form-auto .MuiInputBase-formControl {
  padding: 0;
  padding-right: 35px !important;
  padding-left: 10px;
}

.auto-box .user-autocomplete .form-auto .MuiInputBase-formControl input {
  font-size: 14px;
  color: var(--dark-text);
}

.auto-box
  .user-autocomplete
  .form-auto
  .MuiInputBase-formControl
  .MuiAutocomplete-endAdornment
  button
  svg {
  fill: var(--modal-button-border);
}

.form-auto {
  padding: 0px !important;
}

.input-design-div .tag-box {
  background: white;
}

.table-sec .blue-watch-btn {
  width: 76px;
  height: 25px;
  background: #3a6dd6;
  border-radius: 25px;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  text-transform: capitalize;
}

.table-sec .blue-watch-btn:hover {
  background-color: #3a6dd6;
}

.modal-header-sec .red-btn-main .chat-tag-button-buy {
  min-width: 70px;
  height: 30px;
  background: #008000;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Regular";
  text-transform: capitalize;
  margin: 0 20px 0 20px;
}

.modal-header-sec .red-btn-main .chat-tag-button-sell {
  min-width: 70px;
  height: 30px;
  background: #ff0000;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Regular";
  text-transform: capitalize;
  margin: 0 20px 0 20px;
}

.modal-header-sec .red-btn-main .btn-hover:hover {
  display: none;
}

.modal-header-sec .red-btn-main .chat-tag-button-watch {
  /* width: 76px;
  height: 25px; */
  min-width: 70px;
  height: 30px;
  background: #3a6dd6;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  line-height: 23px;
  font-family: "Poppins-Regular";
  text-transform: capitalize;
  margin: 0 20px 0 20px;
}

.red-btn-main .buy-btn-color {
  background: #008000;
}

.red-btn-main .buy-btn-color:hover {
  background: #008000;
}

.red-btn-main .sell-btn-color {
  background: #ff0000;
}

.red-btn-main .sell-btn-color:hover {
  background: #ff0000;
}

.red-btn-main .watch-btn-color {
  background: #3a6dd6;
}

.red-btn-main .watch-btn-color:hover {
  background: #3a6dd6;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.active-box {
  background-color: var(--script-active-tab);
}

.flex {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
}

.flex {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 26px;
  margin-bottom: 10px;
}

.stopLoss {
  color: #ff0000;
}

.targetAchived {
  color: #008000;
}

.notification-sec {
  bottom: 11px;
  z-index: 9999;
  left: 105px;
}

.notification-sec .MuiPaginationItem-rounded {
  /* color: #ffffff;  */
  color: var(--dark-text);
  /* background: #283142;  */
  background: var(--table-action-button-background);
  height: 22px;
  min-width: 22px;
  margin: 0 0 0 10px;
  font-size: 12px;
  line-height: 17px;
  font-family: "Poppins-Regular";
}

.notification-sec .Mui-selected,
.notification-sec .MuiPaginationItem-rounded:hover {
  /* background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0%
    0%; */
  background: var(--gradient-button);
  color: var(--light-text);
}

.common-dropdown .MuiSelect-select {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  color: var(--table-header-text);
}

.common-dropdown .MuiSvgIcon-root {
  color: var(--modal-button-border);
}

.common-dropdown.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.common-dropdown.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.common-dropdown.MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border: 1px solid var(--modal-button-border);
}

.chart-color-setting {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 20px;
}

.chart-color-setting .common-dropdown {
  width: 35%;
  padding: 2px 2px;
  border: 1px solid var(--modal-button-border);
  border-radius: 6px;
}

.chart-color-setting .chart-color-box {
  flex-grow: 1;
  margin: 0;
}

.color-input fieldset {
  border: 0;
}

.color-input button,
.color-input input {
  border: 1px solid var(--modal-button-border);
}

.color-input .MuiInputBase-root {
  padding-left: 0;
}

.footer-pagination-box {
  gap: 15px;
  margin-right: 0px;
  margin-bottom: 5px;
}

.footer-pagination-box .pagination-input {
  width: 100%;
  /* max-width: 40px; */
  max-width: 55px;
}

.footer-pagination-box .pagination-input input {
  height: auto;
  font-size: 14px;
  line-height: 1;
  padding: 5px 10px;
  text-align: center;
  color: #fff;
}

.noti-popup-box {
  padding: 15px;
}

.noti-popup-box .single-message-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 25px;
  background-color: var(--modal-header-button-background);
}

.noti-page-box .single-message-box {
  background-color: var(--menu-box-background);
}

.noti-popup-box .single-message-box,
.noti-page-box .single-message-box {
  padding: 5px 10px;
  border-radius: 5px;
}

.noti-popup-box .single-message-box.no-record{
  text-align: center;
}

.noti-popup-box .single-message-box:not(:last-child),
.noti-page-box .single-message-box:not(:last-child) {
  margin-bottom: 10px;
}

.noti-popup-box .single-message-box p,
.noti-page-box .single-message-box p {
  font-size: 14px;
}

.noti-popup-box .single-message-box .notification-message-text {
  max-width: 70%;
}

.noti-popup-box .single-message-box .notification-time-text {
  font-size: 12px;
  color: #808080;
}

.noti-popup-box .noti-popup-btn {
  text-align: end;
}

.noti-page-box .noti-page-btn {
  text-align: center;
}

.noti-popup-box .noti-popup-btn button {
  width: 100%;
}

.noti-popup-box .noti-popup-btn button,
.noti-page-box .noti-page-btn button {
  font-family: "Poppins-Regular";
  border-radius: 6px;
  border: none;
  background: var(--gradient-button);
  color: var(--light-text);
  text-transform: capitalize;
  margin-top: 10px;
}

.anychart-control.anychart-plot-controls.anychart-plot-controls-hidden,
.anychart-control.anychart-plot-controls.anychart-control-hover,
.anychart-control.anychart-plot-controls {
  display: none;
}

.header-search-box {
  width: 300px;
  margin-right: 5px;
}

.header-search-box-resize {
  width: 230px;
}

.MuiAutocomplete-root.header-search-box
  .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 0;
  background: var(--header-svg-background);
  border-radius: 8px;
}

.header-search-box .MuiAutocomplete-endAdornment,
.header-search-box fieldset {
  display: none;
}

.header-search-box.MuiAutocomplete-root .MuiOutlinedInput-root input {
  color: var(--sidebar-border);
  height: 35px;
  box-sizing: border-box;
  padding: 5px 10px;
  color: #ffffff;
  font-size: 15px;
}

.search-btn
  .header-search-box
  .search-input
  .MuiOutlinedInput-root
  input::placeholder {
  color: #ffffff !important;
}

.text-field-box {
  position: relative;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: start;
  margin: 20px 20px 0 0;
}

.text-field-box .form-group .text-field-recent input {
  width: 80%;
  background: var(--textfield-background);
  padding: 7.5px 13px 7.5px 13px;
}

.report-filter-flex-box {
  display: flex;
  column-gap: 15px;
  margin-bottom: 10px;
}

.report-date-range-box {
  cursor: pointer;
}

.report-date-range-box .form-control input {
  padding: 7px 10px 7px 10px;
  margin: 0;
  background-color: transparent;
  color: var(--dark-text);
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  border: none;
  border-width: 1px;
  border: 1px solid var(--modal-button-border);
  border-radius: 4px;
  cursor: pointer;
}

.report-modal-range-box .form-control input {
  /* width: 215px; */
  width: 100%;
}

.report-date-range-box .form-control input::selection {
  background-color: transparent;
}

.report-date-range-calender-box {
  position: absolute;
  z-index: 9999;
  margin-top: 10px;
}

.report-date-range-calender-box.date-display-none {
  display: none;
}

.report-date-range-calender-box
  .responsive-date-range-picker
  .rdrDefinedRangesWrapper {
  background: transparent;
  border: none;
}

.report-date-range-calender-box
  .responsive-date-range-picker
  .rdrCalendarWrapper {
  background-color: var(--modal-intraday-button--background);
}

.select-medium-theme
  .report-date-range-calender-box
  .responsive-date-range-picker
  .rdrCalendarWrapper {
  background-color: var(--modal-header-svg-background);
}

.report-date-range-calender-box .rdrCalendarWrapper .rdrNextPrevButton {
  background: var(--gradient-button);
}

.report-date-range-calender-box .rdrCalendarWrapper .rdrSelected,
.report-date-range-calender-box .rdrCalendarWrapper .rdrInRange,
.report-date-range-calender-box .rdrCalendarWrapper .rdrStartEdge,
.report-date-range-calender-box .rdrCalendarWrapper .rdrEndEdge {
  background-color: var(--modal-header-svg-background);
}

.select-medium-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrSelected,
.select-medium-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrInRange,
.select-medium-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrStartEdge,
.select-medium-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrEndEdge {
  background-color: var(--modal-intraday-button--background);
}

.report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrDayPassive
  .rdrDayNumber
  span {
  color: #00000083;
  font-family: "Poppins-Regular";
}

.select-medium-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrDayPassive
  .rdrDayNumber
  span,
.select-dark-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrDayPassive
  .rdrDayNumber
  span {
  color: #ffffff83;
  font-family: "Poppins-Regular";
}

.select-medium-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrDayNumber
  span,
.select-dark-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrDayNumber
  span {
  color: #ffffff;
  font-family: "Poppins-Regular";
}

.report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrDayToday
  .rdrDayNumber
  span:after {
  background: var(--gradient-button);
}

.report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrMonthAndYearPickers
  select {
  font-family: "Poppins-Regular";
  color: #000000;
  border: 1px solid #ffffff;
  background-image: url("../images/svg/downArrow.svg");
  background-size: 10px 10px;
}

.select-light-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrMonthAndYearPickers
  select {
  background-image: url("../images/svg/downArrowBlack.svg");
  border: 1px solid var(--modal-header-svg-background);
}

.report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrMonthAndYearPickers
  select::-webkit-scrollbar {
  display: none;
}

.report-date-range-calender-box .rdrCalendarWrapper .rdrWeekDay {
  font-family: "Poppins-Regular";
  color: #000000;
}

.report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrMonthAndYearPickers
  select
  option {
  background-color: var(--modal-intraday-button--background);
}

.report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrMonthAndYearPickers
  select
  option:hover {
  background-color: var(--modal-header-button-active-background);
}

.select-medium-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrMonthAndYearPickers
  select,
.select-dark-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrMonthAndYearPickers
  select,
.select-medium-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrWeekDay,
.select-dark-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrWeekDay {
  color: #ffffff;
  font-family: "Poppins-Regular";
}

.select-light-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrPprevButton
  i,
.select-medium-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrPprevButton
  i {
  border-color: transparent rgb(255, 255, 255) transparent transparent;
}

.select-light-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrNextButton
  i,
.select-medium-theme
  .report-date-range-calender-box
  .rdrCalendarWrapper
  .rdrNextButton
  i {
  border-color: transparent transparent transparent rgb(255, 255, 255);
}

.date-range-btns {
  position: absolute;
  top: -40px;
  left: 4px;
  background: #fff;
  width: 83%;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  background-color: var(--modal-intraday-button--background);
  padding: 10px;
}

.select-medium-theme .date-range-btns {
  background-color: var(--modal-header-svg-background);
}

.date-range-btns button {
  color: #ffffff;
  text-transform: none;
  background: var(--gradient-button);
  font-family: "Poppins-Regular";
}

.select-dark-theme .date-range-btns button {
  color: #000000;
}

.select-dark-theme .date-range-btns button:disabled {
  color: #0000009a;
}

.add-sprit-btn-box {
  width: 40px;
  height: 40px;
  margin: 0;
}

.add-sprit-btn-box .add-sprit-btn .add-sprit-icon {
  width: 19px;
  height: 19px;
  object-fit: contain;
}

.exit-stock-modal-box {
  margin-top: 0px;
}

.tracking-window-header-btn-flex {
  display: flex;
  column-gap: 10px;
}

.search-autocomplete-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 6px;
  margin-bottom: 5px;
}

.search-autocomplete-list-item .search-autocomplete-btn,
.search-autocomplete-list-item .search-autocomplete-btn:hover {
  min-width: 5px;
  /* padding: 3px 6px; */
  font-size: 10px;
  margin-left: 10px;
}

.search-autocomplete-list-item .search-autocomplete-title {
  font-size: 12px;
  cursor: pointer;
}

/* Draw Chart */
.handle-draw-chart-main {
  position: fixed;
  /* background: var(--gradient-button); */
  z-index: 999;
  width: fit-content;
}

.draw-chart-menu-wrapper {
  width: 50px;
  height: 50px;
  color: var(--dark-text);
  background: var(--gradient-button);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.select-light-theme .draw-chart-menu-wrapper {
  background: transparent linear-gradient(90deg, #287c85 0%, #44a147 100%) 0% 0% no-repeat padding-box;
}

.select-medium-theme .draw-chart-menu-wrapper {
  background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) 0% 0% no-repeat padding-box;
}

.select-dark-theme .draw-chart-menu-wrapper {
  background: transparent linear-gradient(180deg, #a5ff7c 0%, #79ffd4 100%);
}

.select-dark-theme .model-header-left .tool-icon {
  filter: invert(100%);
}

.draw-chart-menu-wrapper-inner {
  width: 50px;
  height: 50px;
  z-index: 9999;
  position: absolute;
}

.draw-chart-menu-wrapper .modal-header {
  min-height: 50px;
  padding: 8.5px 8px 8.5px 16.04px;
  background: var(--modal-header-background);
}

/* Drawing Tool */
.drawing-modal-wrapper {
  min-width: 135px;
  max-width: 200px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.drawing-tool-box {
  min-width: 100px;
  width: 100%;
  min-height: 50px;
  height: 100%;
  padding: 10px;
  background: var(--modal-header-background);
}

.tool-wrapper {
  width: 35px;
  height: 35px;
  color: var(--dark-text);
  background: var(--modal-header-svg-background);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.select-light-theme .tool-wrapper {
  color: #000000;
  background: #009856;
}

.select-medium-theme .tool-wrapper {
  color: #ffffff;
  background: #283142;
}

.select-dark-theme .tool-wrapper {
  color: #ffffff;
  background: #ffffff19;
}

.fill-color-tool-wrapper {
  position: relative;
}

.select-light-theme .selected-annotation,
.select-medium-theme .selected-annotation,
.select-dark-theme .selected-annotation {
  background-color: #080a0d;
}

.drawing-tool-flex {
  max-width: 135px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  column-gap: 15px;
  row-gap: 10px;
  padding: 15px 25px;
}

.drawing-tool-button .drawing-tool-icon {
  width: 22px;
  height: 22px;
  object-fit: contain;
}

.drawing-picker-main {
  position: relative;
}

/* Edit Target Loss */

.edit-target-loss-wrapper {
  padding: 30px 30px 15px 30px;
  border-top: 1px solid var(--modal-border);
}

.edit-target-loss-wrapper .cus-input-grp {
  width: 100%;
  margin: 0 0 15px 0;
}

.edit-target-loss-wrapper .cus-input-grp .cus-lable {
  font-size: 18px;
  line-height: 25px;
  font-family: "Poppins-Regular";
  /* color: #ffffff; */
  color: var(--dark-text);
  text-align: left;
  margin: 0 0 15px 0;
}

.target-loss-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.form-group-flex {
  display: flex;
  column-gap: 15px;
  justify-content: space-between;
  align-items: center;
}

.form-group-flex .form-group {
  width: 100%;
}

.checkbox-form-group {
  width: 45px;
  height: 45px;
}

.drawing-tool-button {
  position: relative;
}

.drawing-tool-button .color-picker input {
  padding: 6px 9px;
  opacity: 0;
  cursor: pointer;
}

.drawing-tool-button .not-allowed input {
  cursor: not-allowed;
}

.stroke-color-icon {
  width: 22px;
  height: 22px;
  background-color: transparent;
  border: 2px solid;
  position: absolute;
  top: 0px;
  right: 1px;
  cursor: pointer;
}

.fill-color-icon {
  width: 20px;
  height: 20px;
  border: none;
  position: absolute;
  top: 18px;
  right: 8px;
}

.analysis-window-btn .menu-logo {
  width: 100%;
  max-width: 20px;
  height: 100%;
  max-height: 20px;
  object-fit: cover;
}

.mini-chart-size-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.mini-chart-size-box .mini-chart-size-input {
  width: 100%;
  max-width: 90px;
}

.mini-chart-size-box .form-control input {
  padding: 4px 28px 4px 5px !important;
}

.primary-btn-main .edit-order-delete-btn {
  align-items: flex-end;
  width: auto;
  padding: 12px 15px;
}

.primary-btn-main .edit-order-delete-btn .action-btn-img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.anychart-download {
  background-color: var(--chart-background);
  border-radius: 5px;
  position: relative;
}

.tag-indicator-circle {
  display: none;
}

.hide-chart-header .tag-indicator-circle {
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

/* toolbar style start */
.showed-option {
  width: 100%;
  display: none;
}

.showed-option.show {
  display: flex;
}

.button-wraps {
  width: 100%;
  display: flex;
  background: var(--modal-toggle-bg);
  border-radius: 30px;
  margin: 10px 20px;
  overflow: hidden;
  position: relative;
}

.select-light-theme .button-wraps {
  background: #80dd83;
}

.select-medium-theme .button-wraps {
  background: #272f3f;
}

.select-dark-theme .button-wraps {
  background: #131313;
}

.button-wraps .toggle {
  width: 50%;
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 30px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.button-wraps .toggle img {
  height: 12px;
}

.orientation-option.horizon .drawing-modal-wrapper {
  /* max-width: inherit; */
  /* width: auto; */
  min-width: 135px;
  max-width: fit-content;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
}

.orientation-option.horizon .drawing-tool-flex {
  max-width: inherit;
  /* width: 490px; */
}

.orientation-option.horizon .button-wraps {
  flex-wrap: wrap;
  border-radius: 20px;
  margin: 10px 10px;
}

.orientation-option.horizon .button-wraps .toggle {
  border-radius: 8px;
  height: 50%;
}

.orientation-option.horizon .showed-option.show {
  display: flex;
  width: 50px;
  flex-wrap: wrap;
}

.modal-body-toolbar {
  display: flex;
  flex-wrap: wrap;
}

.orientation-option.horizon .cus-vertical-center {
  flex-wrap: wrap-reverse;
  gap: 5px;
}

.orientation-option.horizon .modal-body {
  flex-wrap: nowrap;
}

.tool-wrapper input {
  height: 100%;
}

.orientation-option.horizon .opacity-btn .css-q51it5 {
  transform: rotate(-90deg);
  width: 45px;
}

.orientation-option.horizon .model-header-left {
  margin: auto;
}

.orientation-option.horizon .opacity-btn {
  background: none !important;
  width: 100%;
  height: 60px;
  min-width: inherit !important;
  position: relative;
  top: 5px;
}

.orientation-option.horizon .opacity-btn p {
  color: #ffffff;
  font-weight: 500;
  position: relative;
  top: -25px;
}

.orientation-option.horizon .modal-wrapper .modal-header {
  padding: 0px 8px 8.5px 16.04px;
}

.orientation-option.horizon .button-wraps .toggle {
  padding: 0;
  width: 100%;
  align-items: center;
}

.button-wraps:after {
  width: 50%;
  height: 100%;
  background: var(--modal-active-bg);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 20px;
}

.select-light-theme .button-wraps:after {
  background: #009856;
}

.select-medium-theme .button-wraps:after {
  background: transparent linear-gradient(180deg, #5863E5 0%, #8789FB 100%);
}

.select-dark-theme .button-wraps:after {
  background: transparent linear-gradient(180deg, #8de962 0%, #67efc4 100%);
}

.orientation-option.horizon .button-wraps:after {
  width: 100%;
  height: 50%;
  bottom: 0;
  top: inherit;
}

.orientation-option .stroke-color-icon,
.orientation-option .fill-color-icon {
  top: 0px;
}

.orientation-option.horizon .stroke-color-icon,
.orientation-option.horizon .fill-color-icon {
  top: 0px;
}

.model-header-left fieldset {
  display: none;
}

.tool-wrapper {
  position: relative;
}

.tool-wrapper .close-bg {
  background: #d7494a;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  position: absolute;
  right: -7px;
  top: -7px;
  z-index: 2;
  display: none;
}

.tool-wrapper .add-bg {
  background: #2ebe54;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  position: absolute;
  right: -7px;
  top: -7px;
  z-index: 2;
  padding: 3px;
  display: flex;
}

.orientation-option.horizon .drawing-tool-flex {
  padding: 15px 5px;
  /* padding-right: 50px; */
  padding-right: 20px;
  height: 110px;
}

.drawing-tool-flex.remove-zone {
  border-top: 1px solid var(--modal-seprator);
  display: none;
}

.select-light-theme .drawing-tool-flex.remove-zone {
  border-top: 1px solid #80dd83;
  
}

.select-medium-theme .drawing-tool-flex.remove-zone {
  border-top: 1px solid #333333;
  
}

.select-dark-theme .drawing-tool-flex.remove-zone {
  border-top: 1px solid #131313;
  
}

.orientation-option.horizon .drawing-tool-flex.remove-zone {
  border-left: 1px solid var(--modal-seprator);
  border-top: 0;
  padding-left: 20px;
  /* width: auto; */
  padding-right: 20px;
}

.select-light-theme .orientation-option.horizon .drawing-tool-flex.remove-zone {
  border-left: 1px solid #80dd83;
  
}

.select-medium-theme .orientation-option.horizon .drawing-tool-flex.remove-zone {
  border-left: 1px solid #333333;
  
}

.select-dark-theme .orientation-option.horizon .drawing-tool-flex.remove-zone {
  border-left: 1px solid #131313;
  
}

.showed-option.show ~ .drawing-tool-flex.remove-zone {
  display: flex;
}

.showed-option.show ~ .drawing-tool-flex .tool-wrapper .close-bg {
  display: inline-block;
}

/* toolbar style end */

.favorite-watchlist-section {
  width: 100%;
  margin-top: 30px;
}

.favorite-heading-main .favorite-heading-text {
  font-size: 18px;
  line-height: 25px;
  font-family: "Poppins-Medium";
  color: var(--dark-text);
}

.favorite-list-main {
  max-height: 80px;
  overflow: auto;
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.favorite-list-main::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.favorite-list-main::-webkit-scrollbar-track {
  /* background: gray; */
}

.favorite-list-main::-webkit-scrollbar-thumb {
  background-color: var(--modal-sorting-inner-text-bg);
  border-radius: 10px;
}

.favorite-item-box {
  width: 48%;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.favorite-item-box .favorite-item-text {
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Light";
  color: var(--dark-text);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-dark-theme .active-box .favorite-item-text {
  color: #000000;
}

.select-light-theme .active-box .favorite-item-text {
  color: #fff;
}

.watchlist-item-box {
  width: 100%;
}

.watchlist-item-box .watchlist-item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.snapshot-chart-box {
  background: var(--chart-background);
}

.input-none .crosshair-sec input,
.input-none .color-sec input {
  display: none;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-5 {
  margin-right: 5px !important;
}

.ml-0 {
  margin-left: 0;
}

/* .chart-header-left .chart-title{
  position: absolute;
    top: 10%;
}
.chart-header-left .pos-center{
  position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.hover-detail-sec .one-min-bg{
  position: absolute;
  bottom: 15%;
  margin-top: 0;
}
 */
.mr-0 {
  margin-right: 0 !important;
}

.auto-box .report-autocomplete {
  width: 260px;
}

.auto-box
  .report-autocomplete
  .form-auto
  .MuiInputBase-formControl
  .MuiAutocomplete-endAdornment
  button
  svg {
  display: none;
}

.auto-box .report-autocomplete .form-auto .MuiInputBase-formControl input {
  font-size: 16px;
}

.auto-box .report-autocomplete .form-auto .MuiInputBase-formControl {
  padding: 5px 10px;
}

.drawing-tool-flex .tool-thickness-box,
.drawing-tool-flex .tool-thickness-box .tool-thickness-form-control,
.drawing-tool-flex .tool-thickness-box .tool-thickness-select {
  height: 35px;
  width: 35px;
}

.drawing-tool-flex .tool-thickness-box .MuiSelect-select {
  padding: 1.38px 4px !important;
  width: 25px;
}

.drawing-tool-flex .tool-thickness-box .MuiSelect-select img {
  width: 25px;
}

.password-field-box .pass_position {
  position: absolute;
  right: 13px;
}

.password-field .MuiInputBase-adornedEnd {
  padding-right: 0;
}

.password-field .icon_end_icon,
.custom_form_control .icon_end_icon {
  /* color: #fff; */
  color: var(--dark-text);
}

.change-password-box {
  margin-top: 15px;
}

.change-password-error {
  line-height: 18px;
  font-size: 12px;
}

.custom-reset-inner .reset-sub-heading {
  margin-bottom: 30px;
}

.list-heading-main .list-heading-text {
  font-size: 25px;
  line-height: 32px;
  font-family: "Poppins-Medium";
  color: var(--dark-text);
}

.select-chart-card {
  display: flex;
}

.select-chart-card .chart-card-img-box img {
  width: 20px;
  height: 20px;
}

.select-chart-card .chart-card-title-box {
  margin-left: 15px;
}

.select-chart-card .chart-card-title-box .chart-card-title {
  color: var(--dark-text);
  font-size: 14px;
  line-height: 23px;
  font-family: "Poppins-Medium";
}

.category-select-box .common-dropdown {
  min-width: 150px;
  background: var(--header-svg-background);
  border-radius: 4px;
  padding: 5px 10px 7px 10px;
  color: white;
  text-transform: capitalize;
}

.category-select-box .common-dropdown .MuiSvgIcon-root {
  fill: #ffffff;
}

.category-select-box .MuiOutlinedInput-input.MuiSelect-select {
  color: #ffffff !important;
  /* color: var(--dark-text); */
}

.category-select-box .MuiSelect-select {
  padding: 0px;
}

.transaction-table .MuiDataGrid-footerContainer {
  display: none;
}

.buy-min-btn-sec .blue-btn :hover {
  background: #3a6dd6 !important;
}

.buy-min-btn-sec .red-btn :hover {
  background: #ff0000 !important;
}

.buy-min-btn-sec .green-btn :hover {
  background: #008000 !important;
}

.loader-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--dark-text);
}

.default-cursor {
  cursor: default;
}

/* .table-sec .MuiDataGrid-row .MuiDataGrid-cell{
  outline: none ;
} */

.custom-select-box .MuiOutlinedInput-input.MuiSelect-select {
  font-family: "Poppins-Light";
}

.analysis-table .MuiDataGrid-columnHeadersInner {
  width: 100%;
}

.mini-chart-button:first-child {
  height: 80px;
}

.btn-with-border {
  border: 1px solid var(--modal-header-svg-background);
  background: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.header-exit-all-main {
  margin-left: 10px;
}

.header-exit-all-main .primary-btn-main .header-btn {
  padding: 7px 10px;
  font-size: 14px;
  line-height: 18px;
}

/* Index Management Start */

.index-management-add-flex {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.main-content-sec .add-index {
  width: 140px;
  background: var(--gradient-button);
  color: white;
}
.select-dark-theme .main-content-sec .add-index {
  color: #000000;
}

.name-warp-add-index {
  margin-bottom: 0px;
}

.name-warp-add-index .cus-input-grp .cus-lable {
  margin: 0 0 8px 0;
}

.add-index-input-main {
  margin-top: 0px;
  margin-bottom: 10px;
}

.add-index-input-main .form-group .form-control input {
  padding: 12.5px 14px;
  background: var(--textfield-background);
}

.index-list-checkbox-main .list-checkbox svg {
  fill: var(--dark-text);
}

.index-list-checkbox-main .list-checkbox.checkbox-disabled svg {
  opacity: 0.5;
}

.stocks-list-wrapper .stocks-list-heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-content-sec .add-stocks {
  width: 150px;
  background: var(--gradient-button);
  color: white;
}

.stocks-list-flex .add-index-input-main {
  margin-bottom: 0px;
}

.stocks-list-flex .primary-btn-main {
  margin-left: 15px;
}

.stocks-list-flex .add-index-input-main .form-group .form-control input {
  padding: 9.5px 14px;
}

.menu-three-dot-btn {
  position: relative;
}

.analysis-menu .MuiPaper-elevation ul {
  max-width: 165px;
  height: fit-content;
  max-height: 200px;
  overflow: auto;
}

.analysis-menu .MuiPaper-elevation ul::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.analysis-menu .MuiPaper-elevation ul::-webkit-scrollbar-track {
  /* background: gray; */
}

.analysis-menu .MuiPaper-elevation ul::-webkit-scrollbar-thumb {
  background-color: var(--modal-sorting-inner-text-bg);
  border-radius: 10px;
}

.analysis-menu .MuiPaper-elevation ul li {
  width: 100%;
  height: fit-content;
}

.select-light-theme .analysis-menu ul .li-active,
.select-light-theme .analysis-menu ul li:hover {
  background: #009856 !important;
  color: #fff !important;
}

.select-medium-theme .analysis-menu ul .li-active,
.select-medium-theme .analysis-menu ul li:hover {
  background: transparent linear-gradient(180deg, #5863e5 0%, #8789fb 100%) !important;
  color: #fff !important;
}

.select-dark-theme .analysis-menu ul .li-active,
.select-dark-theme .analysis-menu ul li:hover {
  background: transparent linear-gradient(180deg, #a5ff7c 0%, #79ffd4 100%) !important;
  color: #000000 !important;
}

.analysis-menu ul li.remove-watchlist-bg,
.analysis-menu ul li.remove-watchlist-bg:hover {
  background-color: #ff0000 !important;
  color: #fff !important;
  background: #ff0000 !important;
}

/* Index Management End */
.custom-mobile-grid-btn {
  display: none;
}

.mobile-tools {
  display: none !important;
}

.mobile-view-chart {
  min-width: 55px;
  display: none;
}

.stock-title-box {
  display: flex;
}

.stock-title-box-block {
  display: block;
}

.mobile-view-chart svg path {
  fill: #fff;
}

.mobile-view-chart svg {
  width: 15px;
  height: 15px;
}

.primary-btn-main .custom-btn-second:hover {
  background-image: linear-gradient(rgb(85, 164, 88), #75a691) !important;
}

.form-group .custom-form-input input::placeholder {
  color: #8ea08e !important;
}

.drop-down {
  background-color: #8ea08e !important;
}

.select-dark-theme .table-sec .analysis-table .MuiDataGrid-columnHeaderTitle {
  color: #ffff;
}

.select-dark-theme .table-sec .analysis-table .MuiDataGrid-withBorderColor {
  color: #ffffff85;
}

.index-btn-main {
  display: flex;
  justify-content: center;
}

.text-data {
  color: var(--dark-text);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54vh;
  margin-left: 5px;
}

.stock-trade-price {
  display: flex;
}

.date-placeholder .MuiInputLabel-root,
.date-placeholder .Mui-focused {
  color: #75a691 !important;
  text-align: center;
  padding-bottom: 3px;
}

.custom-placeholder::placeholder {
  color: white !important;
}

/* .css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: rgb(35 31 31 / 26%);
} */

.login-inner-main {
  color: rgb(35 31 31 / 26%);
}

/* Index Management End */

/* report model start */
.report-modal .modal-ul-li .modal-ul .modal-li {
  margin: 0;
  font-size: 12px;
}

.report-modal .modal-ul-li .modal-ul .modal-li {
  width: 90px;
}

.report-modal .date-range-sec {
  display: block;
}

.report-modal .date-range-wrapper {
  width: 100%;
}

/* report model end */

.responsive-date-range-picker {
  width: 84%;
  max-width: 400px;
  margin: 20px auto;
}

.custom-picker-direction {
  transform: translateY(-115%);
  overflow-x: auto;
  overflow-y: auto;
}

.select-medium-theme .modal-delete .delete-modal-inner-main {
  width: 400px;
}

.toggle-menu-sec {
  box-shadow: 0 0 6px 0 #06070a;
}

.custom-forgot-box {
  text-align: end;
}

/* analysis table filter option css*/
.select-light-theme .MuiDataGrid-panelWrapper {
  background-color: #98d19aab;
}

.select-light-theme .MuiPaper-elevation .MuiButton-textPrimary {
  color: #ffffff;
  text-transform: capitalize;
  background: #009856;
}

.select-light-theme .MuiPaper-elevation .MuiButton-textPrimary:hover {
  background: #61af63;
}

.select-medium-theme .MuiPaper-elevation .MuiButton-textPrimary {
  color: #ffffff;
  text-transform: capitalize;
  background: #283142;
}

.select-medium-theme .MuiPaper-elevation .MuiButton-textPrimary:hover {
  background: #5a76b4;
}

.select-medium-theme .MuiFormLabel-colorPrimary {
  color: #5a76b4;
}

.select-medium-theme .MuiDataGrid-panelWrapper {
  background-color: #1d2330;
}

.select-light-theme
  .MuiFormControlLabel-root
  .MuiFormControlLabel-label.Mui-disabled {
  color: #000000;
}

.select-medium-theme .MuiFormControlLabel-label,
.select-medium-theme
  .MuiFormControlLabel-root
  .MuiFormControlLabel-label.Mui-disabled {
  color: #5a76b4;
}

.select-dark-theme .MuiDataGrid-panelWrapper {
  background-color: #191919;
}

.select-dark-theme .MuiPaper-elevation .MuiButton-textPrimary {
  color: white;
  text-transform: capitalize;
  background-color: #ffffff19;
}

.select-dark-theme .MuiPaper-elevation .MuiButton-textPrimary:hover {
  background-color: #8a8a8a;
}

.select-dark-theme .MuiFormControlLabel-label,
.select-dark-theme
  .MuiFormControlLabel-root
  .MuiFormControlLabel-label.Mui-disabled {
  color: #ffffff;
}

.select-dark-theme .MuiFormControl-fullWidth .MuiInputLabel-root {
  color: white;
}

.select-dark-theme .MuiInput-underline .MuiInputBase-input {
  color: white;
}

.select-medium-theme .MuiInput-input {
  color: white;
}

.select-medium-theme .MuiInput-underline {
  color: #5a76b4;
}

.select-dark-theme .MuiNativeSelect-select option {
  background-color: #191919 !important;
}

.select-light-theme .MuiNativeSelect-select option {
  background-color: #98d19aab !important;
}

.select-light-theme
  .analysis-table-cus
  .MuiFormControl-root
  .MuiInputLabel-root,
.MuiSvgIcon-root path {
  color: rgba(0, 0, 0, 0.54);
}

.select-light-theme .toggle-menu-sec .toggle-list-btn svg,
.select-dark-theme .toggle-menu-sec .toggle-list-btn svg,
.select-medium-theme .toggle-menu-sec .toggle-list-btn svg {
  fill: rgb(255, 255, 255);
}

.select-medium-theme .MuiNativeSelect-select option {
  background-color: #1d2330 !important;
}

.select-medium-theme .MuiInputBase-root .MuiNativeSelect-select {
  color: #5a76b4;
}

.select-dark-theme
  .MuiDataGrid-panel
  .MuiPaper-elevation
  .MuiIconButton-root
  svg,
.select-dark-theme
  .MuiDataGrid-panel
  .MuiFormControl-root
  .MuiInputBase-root
  svg,
.select-dark-theme .MuiFormLabel-colorPrimary {
  fill: #ffffff;
  color: #ffffff;
}

.select-medium-theme
  .MuiDataGrid-menu
  .MuiPaper-elevation
  ul
  li
  .MuiListItemIcon-root
  svg {
  fill: rgb(255, 255, 255);
}

.select-dark-theme
  .MuiDataGrid-menu
  .MuiPaper-elevation
  ul
  li
  .MuiListItemIcon-root
  svg {
  fill: #fff;
}

.select-medium-theme .MuiDataGrid-filterForm .MuiInputBase-root input,
.select-medium-theme
  .MuiDataGrid-panel
  .MuiPaper-elevation
  .MuiIconButton-root
  svg,
.select-medium-theme
  .MuiDataGrid-panel
  .MuiFormControl-root
  .MuiInputBase-root
  svg {
  color: #5a76b4;
  fill: #5a76b4;
}

.select-dark-theme .time-inner-li.active,
.select-dark-theme .time-frame-dark-text-color.active {
  background: #a5ff7c;
  color: black !important;
}

.MuiDataGrid-panelWrapper
  .MuiDataGrid-panelContent
  .MuiDataGrid-filterFormColumnInput {
  width: 161px;
}

.select-light-theme .MuiDialogContent-root {
  background-color: #e3f2e3;
}

.select-medium-theme .MuiDialogContent-root {
  background-color: #12161f;
  color: white;
}

.select-dark-theme .MuiDialogContent-root {
  background-color: #232323;
  color: white;
}

.select-medium-theme .MuiDialogContent-root span,
.select-medium-theme .MuiDialogContent-root button,
.select-medium-theme .MuiDialogContent-root svg,
.select-medium-theme .MuiDialogActions-spacing button {
  color: #fff !important;
  fill: #fff;
}

.select-dark-theme .MuiDialogContent-root span,
.select-dark-theme .MuiDialogContent-root button,
.select-dark-theme .MuiDialogContent-root svg,
.select-dark-theme .MuiDialogActions-spacing button {
  color: #fff !important;
  fill: #fff;
}

.select-light-theme .MuiDialogContent-root {
  background-color: #e3f2e3;
}

/* analysis filter css end  */

/*  css for report and chart button active */
.report-chart-btn-sec .primary-btn-main .chart-btn-active {
  background: var(--order-report-button-active-background) !important;
  border-radius: 6px;
  color: var(--light-text);
}

.report-chart-btn-main-box .report-btn-active-cus {
  background: var(--gradient-button) !important;
}

.primary-btn-main .repot-btn-custom {
  border-radius: 0 6px 6px 0;
  background-color: transparent !important;
}

.primary-btn-main .report-btn-active-text {
  color: var(--dark-text) !important;
}

.report-chart-btn-main-box .report-btn-active-cus {
  background: linear-gradient(180deg, #a5ff7c, #79ffd4) !important;
  margin-left: 5px !important;
  border-radius: 6px;
  color: white;
}

.header-left .report-chart-btn-main-box {
  background: none !important;
}

.report-chart-btn-sec .primary-btn-main .chart-btn-fix {
  margin-left: 5px !important;
  border-radius: 6px;
}

/* login css for cursor */
.login-inner-main .check-target {
  cursor: default;
}

.modal-comman-details {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 1000;
}

.modal-comman-inner-style {
  background-color: var(--modal-sorting-inner-text-bg);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: white;
  /* border: none;  */
}

.modal-title {
  color: var(--dark-text);
}

.check-text .check-text-chld .check-text-child input::placeholder {
  color: var(--dark-text) !important;
}

.date-placeholder .MuiInputLabel-root,
.date-placeholder .Mui-focused {
  color: var(--dark-text) !important;
  text-align: center;
  padding-bottom: 3px;
}

.cus-link-forgot-page {
  display: flex;
  justify-content: end;
}

.input-grp-confirm-password {
  margin-top: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000000;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #dbffde;
}

.select-medium-theme input:-webkit-autofill,
.select-medium-theme input:-webkit-autofill:hover,
.select-medium-theme input:-webkit-autofill:focus,
.select-medium-theme input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  color: #fff;
  caret-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #1d2330;
}

.select-dark-theme input:-webkit-autofill,
.select-dark-theme input:-webkit-autofill:hover,
.select-dark-theme input:-webkit-autofill:focus,
.select-dark-theme input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  caret-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #000000;
}

.cus-reset-password {
  background: var(--textfield-background);
}

.list-label-main {
  padding: 0;
  margin: 0;
}

.list-label-main .timeframe-btn {
  display: none;
  padding: 3px 7px;
}

.list-label-main:hover .label-btn {
  display: none;
}

.list-label-main:hover .timeframe-btn {
  display: block;
}

.custom-def-setting .modal-chart-img.active,
.custom-dark-setting {
  filter: invert(1);
}

.select-dark-theme .MuiMenuItem-gutters:hover {
  background-color: #ffffff66;
}

.select-medium-theme .MuiMenuItem-gutters:hover {
  background-color: #5a76b4;
}

.select-light-theme .MuiMenuItem-gutters:hover {
  background-color: #b6e5b8;
}

/* table filter drop down css */
.MuiPaper-elevation .MuiList-padding .MuiDivider-fullWidth {
  margin-top: 0px;
  margin-bottom: 0px;
}

/* table pagintion icon  */
.select-dark-theme .MuiTablePagination-actions .MuiButtonBase-root svg,
.select-medium-theme .MuiTablePagination-actions .MuiButtonBase-root svg {
  fill: white;
}

/* default setting menu icon and exit from window icon css */
.select-dark-theme .name-warp-tracking-window .MuiInputBase-root svg,
.select-medium-theme .name-warp-tracking-window .MuiInputBase-root svg,
.select-dark-theme .chart-color-setting .MuiInputBase-root svg,
.select-medium-theme .chart-color-setting .MuiInputBase-root svg {
  fill: white;
}

.report-date-box-cus,
.select-dark-theme .report-date-box-cus button svg,
.select-medium-theme .report-date-box-cus button svg {
  display: flex;
  fill: white;
}

/* profile btn css  start*/
.profile-btn-main {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.primary-btn-main .profile-save-btn {
  margin: 20px 0 30px 0;
  padding: 8px;
}

/* profile btn css  end*/
.custom_form_control .MuiOutlinedInput-notchedOutline :hover {
  border-color: transparent !important;
}

.custom_form_control .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.profile-email .form-control .Mui-disabled {
  color: var(--dark-text);
  -webkit-text-fill-color: var(--dark-text);
}

.MuiPaper-elevation ul::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.select-medium-theme .MuiPaper-elevation ul::-webkit-scrollbar-thumb {
  background-color: #42506f;
  border-radius: 10px;
}

.select-light-theme .MuiPaper-elevation ul::-webkit-scrollbar-thumb {
  background-color: #009856;
  border-radius: 10px;
}

.select-dark-theme .MuiPaper-elevation ul::-webkit-scrollbar-thumb {
  background-color: #232528;
  border-radius: 10px;
}

/* loader */
.select-dark-theme .loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l1 1s infinite linear alternate;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes l1 {
  0% {
    box-shadow: 20px 0 #a5ff7c, -20px 0 #ffff;
    background: #a5ff7c;
  }

  33% {
    box-shadow: 20px 0 #a5ff7c, -20px 0 #ffff;
    background: #ffff;
  }

  66% {
    box-shadow: 20px 0 #ffff, -20px 0 #a5ff7c;
    background: #ffff;
  }

  100% {
    box-shadow: 20px 0 #ffff, -20px 0 #a5ff7c;
    background: #a5ff7c;
  }
}

.select-medium-theme .loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l2 1s infinite linear alternate;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes l2 {
  0% {
    box-shadow: 20px 0 #1d2330, -20px 0 #526ba2;
    background: #1d2330;
  }

  33% {
    box-shadow: 20px 0 #1d2330, -20px 0 #526ba2;
    background: #526ba2;
  }

  66% {
    box-shadow: 20px 0 #526ba2, -20px 0 #1d2330;
    background: #526ba2;
  }

  100% {
    box-shadow: 20px 0 #526ba2, -20px 0 #1d2330;
    background: #1d2330;
  }
}

.select-light-theme .loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l3 1s infinite linear alternate;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes l3 {
  0% {
    box-shadow: 20px 0 #009856, -20px 0 #b6e5b8;
    background: #009856;
  }

  33% {
    box-shadow: 20px 0 #009856, -20px 0 #b6e5b8;
    background: #b6e5b8;
  }

  66% {
    box-shadow: 20px 0 #b6e5b8, -20px 0 #009856;
    background: #b6e5b8;
  }

  100% {
    box-shadow: 20px 0 #b6e5b8, -20px 0 #009856;
    background: #009856;
  }
}

/* tracking window border css */
.tracking-window-table .MuiDataGrid-cell:focus,
.tracking-window-table .MuiDataGrid-cell:focus-within,
.tracking-window-table .MuiDataGrid-columnHeader:focus,
.tracking-window-table
  .css-15o1rf6-MuiDataGrid-root
  .MuiDataGrid-columnHeader:focus-within,
.tracking-window-table
  .css-15o1rf6-MuiDataGrid-root
  .MuiDataGrid-cell:focus-within,
.analysis-table .MuiDataGrid-cell:focus,
.analysis-table .MuiDataGrid-cell:focus-within,
.analysis-table .MuiDataGrid-columnHeader:focus,
.analysis-table
  .css-15o1rf6-MuiDataGrid-root
  .MuiDataGrid-columnHeader:focus-within,
.analysis-table .css-15o1rf6-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.select-dark-theme .text-field-box .form-group .text-field-recent input {
  background: #232323;
}

.select-dark-theme .recentindex-tab-main .active-box {
  background: transparent linear-gradient(180deg, #a5ff7c 0%, #79ffd4 100%);
}

.select-light-theme .recentindex-tab-main .active-box {
  color: white;
}

.select-dark-theme .recentindex-tab-main .active-box {
  color: black;
}

.select-dark-theme .label-no-custom {
  background: black;
}

/* recent box modal css */
/* .select-medium-theme .opacity-btn-recent-box .MuiSlider-sizeMedium {
  color: #8285F9;
} */
.select-light-theme .slider-modal-icon .MuiSlider-sizeMedium {
  color: #ffffff;
}

.select-medium-theme .slider-modal-icon .MuiSlider-sizeMedium {
  color: #5863e5;
}

.select-dark-theme .slider-modal-icon .MuiSlider-sizeMedium {
  color: #a5ff7c;
}

/* recent box icons button  on right header*/
.select-medium-theme .modal-header-close-svg-recent-box {
  /* .select-medium-theme .modal-minus-svg-recent-box { */
  fill: #8285f9;
}

.select-light-theme .modal-icon-fill {
  fill: #ffffff;
}
.select-medium-theme .modal-icon-fill {
  /* fill: #8285F9; */
  fill: #5863e5;
}
.select-dark-theme .modal-icon-fill {
  fill: #a5ff7c;
}

.select-light-theme .notification-ic {
  filter: invert(42%) sepia(74%) saturate(428%) hue-rotate(80deg)
    brightness(60%) contrast(90%);
}
.select-dark-theme .notification-ic {
  filter: invert(86%) sepia(33%) saturate(410%) hue-rotate(66deg)
    brightness(105%) contrast(100%);
}
.select-medium-theme .notification-ic {
  filter: invert(68%) sepia(53%) saturate(500%) hue-rotate(182deg)
    brightness(97%) contrast(93%);
}
/* default setting iocns */
.select-dark-theme .line-img {
  filter: invert(15.55) sepia(130.15) saturate(1.8) hue-rotate(114deg)
    brightness(2.1);
}
.select-dark-theme .modal-dotted {
  filter: invert(42%) sepia(74%) saturate(428%) hue-rotate(80deg)
    brightness(90%) contrast(90%);
}
.select-light-theme .line-img {
  filter: invert(10%) sepia(74%) saturate(428%) hue-rotate(80deg)
    brightness(90%) contrast(90%);
}
/* sidebar-chart-icon color */
.select-light-theme .chart-list .chart-icon {
  filter: invert(1);
}

.select-dark-theme .chart-list .chart-icon-active {
  filter: invert(1);
}

.setting-ic {
  color: var(--pagination-active-button-background);
}

/* recent box scroll */
.select-dark-theme
  .recent-index-main
  .script-list-main::-webkit-scrollbar-thumb {
  background-color: #79ffd4;
}

/* sorting box css */
.sorting-tag-active {
  background: var(--modal-active-bg);
}

.select-light-theme .tag-main .sort-sr-btn-active {
  border: 1px solid #dbffde;
}

.select-dark-theme .tag-main .sort-active-label {
  color: #000000;
}

.select-light-theme .tag-main .sort-active-label {
  color: #ffffff;
}

.select-dark-theme .category-select-box .common-dropdown .MuiSvgIcon-root,
.select-medium-theme .category-select-box .common-dropdown .MuiSvgIcon-root {
  fill: #ffffff;
}

.filter-btn-main .reset-btn-bg,
.filter-btn-main .reset-btn-bg:hover {
  background: var(--modal-sorting-button);
 }

.filter-btn-main .reset-btn-bg.active,
.filter-btn-main .reset-btn-bg.active:hover {
  background: var(--gradient-button);
}

/* grid color setting line color css */
.check-color-pick-sec .MuiInputBase-formControl::before,
.check-color-pick-sec .MuiInputBase-formControl::after,
.check-color-pick-sec .MuiInputBase-formControl:hover {
  border: transparent !important;
}

/* recent index box css start */
.fav-input-box-active {
  background: var(--fav-script-active-tab);
}

.index-input-grp-fav {
  position: relative;
  margin: 20px 20px 0 0;
  cursor: pointer;
}

.select-dark-theme .fav-active-text {
  color: #000000;
}

.select-light-theme .fav-active-text {
  color: #ffffff;
}
/* recent index box css end */

.time-frame-right
  .default-setting-minute-main
  .MuiInputBase-colorPrimary
  input::placeholder {
  color: var(--dark-text) !important;
}
.custom-chart-header-left {
  display: block;
}

.select-dark-theme .drop-sec svg {
  fill: #000;
}

.row-nums-drop .MuiInputBase-input {
  font-size: 16px;
  line-height: normal;
  padding: 2.5px 10px;
}
.row-nums-drop .MuiSelect-icon {
  fill: #ffffff;
  /* fill: var(--dark-text); */
  /* width: 13px;
  height: 13px; */
  width: 25px;
  height: 20px;
  object-fit: cover;
  top: 4px;
}

.exit-stock-modal-box .exit-catg-option {
  display: none;
}

.chart-color-setting .common-dropdown.active,
.chart-color-setting .common-dropdown:hover {
  border: 1px solid var(--chart-color-dropdown-active-border);
}

.custom-minute-active input:focus {
  border: 1px solid var(--modal-togel-button-active);
  /* background: var(--modal-togel-button-active); */
}

.flip-helper-box {
  background: var(--sidebar-menu-box-button);
  color: var(--light-text);
}
.table-sec .MuiDataGrid-columnHeaderTitleContainer button {
  display: none;
}

.select-medium-theme .custom-script-menu-scrollbar ul {
  &::-webkit-scrollbar-thumb {
    background-color: #526ba2 !important;
  }
}
.select-dark-theme .custom-script-menu-scrollbar ul {
  &::-webkit-scrollbar-thumb {
    background-color: #616161 !important;
  }
}
.select-light-theme .custom-script-menu-scrollbar ul {
  &::-webkit-scrollbar-thumb {
    background-color: #98d19a !important;
  }
}

.select-dark-theme .modal-ul-li .modal-ul .modal-li.active img {
  filter: invert(1);
}
.select-light-theme .modal-ul-li .modal-ul .modal-li img {
  filter: invert(1);
}
.select-light-theme .modal-ul-li .modal-ul .modal-li.active img {
  filter: invert(0);
}
.chart-date-picker .datepicker-wrap input {
  color: var(--dark-text) !important;
}

/* Tow Arrow Zoom */
.hide-main-header {
  display: none;
}

.hide-sidebar-main {
  display: none;
}

.main-content-sec .dashboard-chart-main-zoom {
  height: calc(100vh - 35px);
}

.main-content-sec .tracking-chart-main-zoom {
  height: calc(100vh - 85px);
}

.MuiPopper-root
  .MuiDataGrid-filterForm
  .MuiFormControl-root
  .MuiInputBase-formControl
  .MuiNativeSelect-select {
  padding-left: 10px;
}

.profile-menu-sec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
  margin-top: 20px;
}

.profile-menu-sec .profile-menu-btn {
  width: 45px;
  height: 45px;
  border-radius: 8px;
  background: var(--header-svg-background);
}

.profile-menu-sec .profile-menu-btn:hover {
  background: var(--sidebar-svg-background-hover) 
}

.select-light-theme .profile-menu-sec .profile-menu-btn,
.select-light-theme .profile-menu-sec .profile-menu-btn:hover {
  background: var(--gradient-button);
}

.profile-menu-sec .profile-menu-btn .profile-menu-btn-img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.profile-menu-sec .profile-menu-btn:hover .profile-menu-btn-img { 
  filter: invert(100%);
}

.mini-chart-modal-box:focus-visible {
  outline: none ;
}

/* Auto Square Modal */

.auto-square-wrapper {
  padding: 20px;
  border-top: 1px solid var(--modal-border);
}

.auto-square-flex {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--modal-border);
  border-radius: 5px;
  margin-bottom: 10px;
}

.auto-square-btn-main {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auto-square-category {
  min-width: 92px;
  max-width: 92px;
}

.auto-square-category .auto-square-category-text {
  font-family: "Poppins-Regular";
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
}

.time-picker-wrapper {
  width: 100px;
}

.auto-square-time-main .time-picker-wrapper .time-picker .MuiInputBase-root input {
  font-family: "Poppins-Regular";
  font-size: 16px;
  line-height: 20px;
  color: #ffffff !important;
}

.select-light-theme .auto-square-time-main .time-picker-wrapper .time-picker .MuiInputBase-root input {
  color: #000000 !important;
}

.time-picker-wrapper .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px;
}

.auto-square-btn-main .auto-square-btn {
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Regular";
  width: 100%;
  border-radius: 6px;
  border: none;
  background: var(--gradient-button);
  color: var(--light-text);
  text-transform: capitalize;
}

.show-hide-btn {
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.show-hide-btn.tracking-show-hide-btn {
  bottom: 55px;
}

.select-light-theme .tracking-show-hide-btn .round-btn-main .download-btn {
  background: #b6e5b8 !important;
  border: 1px solid #b6e5b8 !important;
}

.table-no-record-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.table-no-record-main .table-no-record-text {
  font-size: 20px;
  line-height: 27px;
  font-family: "Poppins-Medium";
  color: var(--dark-text)
}